import React, { Fragment } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";

import { Button } from "..";
import { parseFishingCatchPhotoUpload } from "./utils";

const DEFAULT_UPLOAD_ERROR = "There was a problem uploading your photo.";

export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: []
    };
  }

  componentDidMount() {
    const { uploadedFiles } = this.props;
    if (uploadedFiles.length) {
      this.setState({ uploadedFiles });
    }
  }

  parseFileList = async fileList => {
    const { uploadedFiles } = this.state;
    const { limit } = this.props;
    const MAX_FISHING_CATCH_UPLOAD_ERROR = `The max number of photos allowed per fish caught is ${limit}.`;

    if (!window.FileReader) {
      throw new Error(DEFAULT_UPLOAD_ERROR);
    }

    const files = await parseFishingCatchPhotoUpload(fileList);

    const totalFiles = files.concat(uploadedFiles);
    if (limit && totalFiles.length > limit) {
      throw new Error(MAX_FISHING_CATCH_UPLOAD_ERROR);
    }

    return files;
  };

  handleOnChange = async fileList => {
    const { uploadedFiles } = this.state;
    const { handleFileChange } = this.props;

    try {
      const files = await this.parseFileList(fileList);
      this.setState({ uploadedFiles: [...files, ...uploadedFiles] }, () => {
        handleFileChange(this.state.uploadedFiles);
      });
    } catch (err) {
      console.log("err :", err);
      handleFileChange(null, err.message || DEFAULT_UPLOAD_ERROR);
    }
  };

  removeUploadedFile = fileId => {
    const { handleFileChange } = this.props;

    const uploadedFiles = this.state.uploadedFiles.filter(
      file => file.id !== fileId
    );
    this.setState({ uploadedFiles }, () => {
      handleFileChange(this.state.uploadedFiles);
    });
  };

  render() {
    const { id, name, multiple, buttonClassName, buttonText } = this.props;
    const { uploadedFiles } = this.state;

    return (
      <Fragment>
        <input
          id={id}
          name={name}
          multiple={multiple}
          type="file"
          accept="image/*"
          onChange={event => this.handleOnChange(event.target.files)}
          style={{
            display: "none"
          }}
        />
        <label htmlFor={id}>
          <Button className={buttonClassName} component="span" type="button">
            <PublishIcon />
            {buttonText}
          </Button>
        </label>
        {uploadedFiles.map((image, imageIndex) => {
          return (
            <div
              key={`fishingCatch-${image.id}`}
              style={{
                position: "relative"
              }}
            >
              <img
                style={{
                  boxSizing: "border-box",
                  maxWidth: "200px",
                  maxHeight: "200px"
                }}
                src={image.base64 || image.upload_url}
                alt={`Catch ${imageIndex + 1}`}
              />
              <IconButton
                onClick={e => this.removeUploadedFile(image.id)}
                size="medium"
                style={{
                  position: "absolute",
                  right: 0
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

ImageUpload.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  uploadedFiles: PropTypes.array
};

ImageUpload.defaultProps = {
  multiple: true,
  uploadedFiles: []
};
