import { GET_SPECIES } from "../actions/species";

const INITIAL_SPECIES_STATE = [];

export default function speciesReducer(
  state = INITIAL_SPECIES_STATE,
  action = {}
) {
  switch (action.type) {
    case "ADD_SPECIES":
      return state.concat(action.fishingReport);
    case GET_SPECIES:
      return action.payload.species;
    default:
      return state;
  }
}
