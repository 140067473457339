import { SET_FIELDS } from "../actions/fields";

export const DEFAULT_SETTING_STATE = [];

export default function settingsReducer(
  state = DEFAULT_SETTING_STATE,
  action = {}
) {
  switch (action.type) {
    case SET_FIELDS:
      return action.fields;
    default:
      return state;
  }
}
