import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "@reach/router";
import * as Yup from "yup";
import wpc_logo from "../../assets/images/wpc_logo.png";
import catchitlogit_logo from "../../assets/images/catchitlogit_logo.png";
import { Divider } from "@material-ui/core";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  Form,
  Typography,
  TextField,
  Tooltip
} from "../../components";

import { getFields } from "../../actions/fields";
import { verifyCredentials, loginUser } from "../../actions/auth";

const styles = theme => ({
  appLogoImg: {
    margin: "2rem 0 1rem",
    maxWidth: "100%",
    width: "150px"
  },
  container: {
    maxWidth: theme.container.maxWidth,
    margin: `${theme.spacing(4)}px auto`
  },
  card: {
    margin: `0 ${theme.spacing(1)}px`
  },
  cardBody: {
    paddingBottom: theme.spacing(4)
  },
  cardFooter: {
    padding: `${theme.spacing(3)}px 0`,
    textAlign: "center"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `0 ${theme.spacing(4)}px`,
    width: "100%"
  },
  logo: {
    fontWeight: 900,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2)
  },
  logoImg: {
    padding: 16,
    width: 300,
    maxWidth: "100%"
  },
  loginError: {
    color: "red"
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  }
});

const Login = props => {
  const {
    classes,
    isAuthenticated,
    isFirstLogin,
    getFields,
    loginUser,
    verifyCredentials
  } = props;

  useEffect(() => {
    const fetchFields = async () => {
      await getFields();
    };

    fetchFields();
  }, []);

  const [loginError, setLoginError] = useState(null);

  if (isAuthenticated) {
    if (isFirstLogin) {
      return <Redirect to="/reset-password" noThrow />;
    }

    return <Redirect to="/app/fishing-reports" noThrow />;
  }

  return (
    <Container>
      <Card className={classes.card}>
        <CardHeader title>
          <img
            className={classes.appLogoImg}
            src={catchitlogit_logo}
            alt="CatchitLogit logo"
          />
          <Typography className={classes.logo} variant="h6">
            Fisher
          </Typography>
          <Typography variant="h5">
            Log In <Tooltip field="LOGIN_TOOLTIP" />
          </Typography>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <Form
            initialValues={{
              business_license_number: "",
              password: ""
            }}
            validationSchema={Yup.object().shape({
              business_license_number: Yup.string().required(
                "Fisher Number is required."
              ),
              password: Yup.string().required("Password is required.")
            })}
            onSubmit={async values => {
              try {
                const loginResponse = await verifyCredentials(
                  values.business_license_number,
                  values.password
                );

                loginUser({
                  isFirstLogin: loginResponse.data.prompt_password_change
                });
              } catch (err) {
                if (err.response) {
                  const { status } = err.response;

                  if (status === 401) {
                    setLoginError("Incorrect Fisher Number or Password.");
                  }

                  if (status > 401) {
                    setLoginError(
                      "Something went wrong. Please try again later."
                    );
                  }
                } else {
                  setLoginError(
                    "Please check your internet connection and try again."
                  );
                }
              }
            }}
            render={({ handleSubmit }) => {
              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <span className={classes.loginError}>{loginError}</span>
                  <TextField
                    id="business_license_number"
                    name="business_license_number"
                    label="Fisher Number"
                    placeholder="ex. 11111-00000"
                  />
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                  />
                  <Button
                    className={classes.submitBtn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    wide
                  >
                    Log In
                  </Button>
                </form>
              );
            }}
          />
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Typography variant="body1" style={{ marginBottom: "8px" }}>
            Forgot your password?
          </Typography>
          <Typography variant="body1">
            Call us for help to reset your password
          </Typography>
          <Divider style={{ margin: 15 }} />
          <div style={{ textAlign: "center" }}>
            <img className={classes.logoImg} src={wpc_logo} alt="WPC logo" />
            <br />
            <small>Version: {process.env.REACT_APP_VERSION}</small>
          </div>
        </CardFooter>
      </Card>
    </Container>
  );
};

const StyledLogin = withStyles(styles)(Login);

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  isFirstLogin: state.user.isFirstLogin
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFields,
      loginUser,
      verifyCredentials
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StyledLogin);
