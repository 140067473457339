import * as Yup from "yup";
import groupBy from "lodash.groupby";
import numeral from "numeral-es6";

export const TROLLING_METHOD_ID = 2;
export const BOTTOMFISHING_METHOD_ID = 3;
export const SPEARFISHING_METHOD_ID = 4;

export const MAX_FISHING_CATCH_UPLOADS = 5;

const ARRIVAL_TIME_ERROR = "Arrival time is required";
const ARRIVAL_LOCATION_ERROR = "Arrival location is required";
const CATCH_COUNT_ERROR =
  "Count must be a positive whole number, select 0 if no fish were caught";
const CATCH_COUNT_ERROR_SPECIES_WITH_ZERO_COUNT =
  "Please clear the species caught if you intended to have a 0 count";
const CHARTER_ERROR = "Charter is required";
const COUNT_ERROR = "Count must be a positive whole number";
const DEPARTURE_LOCATION_ERROR = "Departure location is required";
const DEPARTURE_TIME_ERROR = "Departure time is required";
const FIRST_NAME_ERROR = "First name is required";
const FISHED_AREAS_ERROR = "Please select a fishing area";
const FISHING_HOURS_ERROR = "Fishing hours must be a positive whole number";
const FISHING_METHOD_ERROR = "Fishing method is required";
const LAST_NAME_ERROR = "Last name is required";
const LOST_TO_PREDATOR_ERROR = "Please select if fish were lost to predator";
const PREDATOR_SPECIES_ERROR = "Predator species is required";
const PRICE_PER_LB_ERROR = "Price per lb must be a positive whole number";
const SPECIES_ERROR = "Species is required";
const CAUGHT_SPECIES_ERROR = "Please enter fish caught";
const START_TIME_ERROR = "Start time is required";
const WEIGHT_ERROR = "Weight must be a positive number";
const VENDOR_LICENSE_ERROR = "Vendor license number is required";
const VESSEL_LICENSE_ERROR =
  "Vessel registration number required, please contact an administrator to update";
const DISPOSITION_TYPE_ERROR = "Please select disposition type";
const TARGET_SPECIES_ERROR = "Please enter target species";
const GEAR_ERROR = "Number of gears is required";
const SPEAR_ERROR = "Number of spears is required";

const CatchesSchema = Yup.object().shape({
  species_id: Yup.number(CAUGHT_SPECIES_ERROR).when("total_count", {
    is: 0,
    then: Yup.number(CAUGHT_SPECIES_ERROR).nullable(),
    otherwise: Yup.number(CAUGHT_SPECIES_ERROR)
      .typeError(CAUGHT_SPECIES_ERROR)
      .positive(CAUGHT_SPECIES_ERROR)
      .integer(CAUGHT_SPECIES_ERROR)
      .required(CAUGHT_SPECIES_ERROR)
  }),
  total_count: Yup.number(CATCH_COUNT_ERROR)
    .typeError(CATCH_COUNT_ERROR) // Will throw if field is left blank which is a null value
    .test(function (value) {
      if (this.parent.species_id && value <= 0) {
        // A species caught is selected with a 0 count
        throw {
          message: CATCH_COUNT_ERROR_SPECIES_WITH_ZERO_COUNT
        };
      }
      return true;
    }),
  total_weight_lbs: Yup.number(WEIGHT_ERROR).when("total_count", {
    is: 0,
    then: Yup.number(WEIGHT_ERROR).nullable(),
    otherwise: Yup.number(WEIGHT_ERROR)
      .typeError(WEIGHT_ERROR)
      .positive(WEIGHT_ERROR)
  }),
  attachments: Yup.array().max(5)
});

const FishingEventSchema = Yup.object().shape({
  fishing_method_id: Yup.number(FISHING_METHOD_ERROR)
    .typeError(FISHING_METHOD_ERROR)
    .positive(FISHING_METHOD_ERROR)
    .integer(FISHING_METHOD_ERROR)
    .required(FISHING_METHOD_ERROR),
  fishing_hours: Yup.number(FISHING_HOURS_ERROR)
    .typeError(FISHING_HOURS_ERROR)
    .positive(FISHING_HOURS_ERROR)
    .required(FISHING_HOURS_ERROR),
  start_time: Yup.date(START_TIME_ERROR)
    .typeError(START_TIME_ERROR)
    .required(START_TIME_ERROR),
  fished_areas_ids: Yup.string()
    .typeError(FISHED_AREAS_ERROR)
    .test("json", FISHED_AREAS_ERROR, function (value) {
      try {
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    }),
  target_species: Yup.array()
    .of(Yup.number().positive().integer())
    .required(TARGET_SPECIES_ERROR),
  catches: Yup.array().of(CatchesSchema).ensure(),
  number_of_spears: Yup.number(SPEAR_ERROR).when("fishing_method_id", {
    is: SPEARFISHING_METHOD_ID,
    then: Yup.number(SPEAR_ERROR).typeError(SPEAR_ERROR).required(SPEAR_ERROR),
    otherwise: Yup.number(SPEAR_ERROR).typeError(SPEAR_ERROR).nullable()
  }),
  number_of_gears: Yup.number(GEAR_ERROR)
    .when("fishing_method_id", {
      is: BOTTOMFISHING_METHOD_ID,
      then: Yup.number(GEAR_ERROR).typeError(GEAR_ERROR).required(GEAR_ERROR),
      otherwise: Yup.number(GEAR_ERROR).typeError(GEAR_ERROR).nullable()
    })
    .when("fishing_method_id", {
      is: TROLLING_METHOD_ID,
      then: Yup.number(GEAR_ERROR).typeError(GEAR_ERROR).required(GEAR_ERROR),
      otherwise: Yup.number(GEAR_ERROR).typeError(GEAR_ERROR).nullable()
    })
});

const CrewMemberSchema = Yup.object().shape({
  first_name: Yup.string(FIRST_NAME_ERROR)
    .typeError(FIRST_NAME_ERROR)
    .required(FIRST_NAME_ERROR),
  last_name: Yup.string(LAST_NAME_ERROR)
    .typeError(LAST_NAME_ERROR)
    .required(LAST_NAME_ERROR),
  business_license_number: Yup.string().default(null).nullable()
});

const SalesOrderItemsSchema = Yup.object().shape({
  species_id: Yup.number(SPECIES_ERROR)
    .typeError(SPECIES_ERROR)
    .positive(SPECIES_ERROR)
    .integer(SPECIES_ERROR)
    .required(SPECIES_ERROR),
  total_count: Yup.number(COUNT_ERROR)
    .typeError(COUNT_ERROR)
    .positive(COUNT_ERROR)
    .integer(COUNT_ERROR)
    .required(COUNT_ERROR),
  total_weight_lbs: Yup.number(WEIGHT_ERROR)
    .typeError(WEIGHT_ERROR)
    .positive(WEIGHT_ERROR)
    .required(WEIGHT_ERROR),
  price_per_lb_usd: Yup.number(PRICE_PER_LB_ERROR)
    .typeError(PRICE_PER_LB_ERROR)
    .positive(PRICE_PER_LB_ERROR)
    .required(PRICE_PER_LB_ERROR),
  sold_to_vendor: Yup.boolean(),
  vendor_business_license_number: Yup.string().when("sold_to_vendor", {
    is: true,
    then: Yup.string(VENDOR_LICENSE_ERROR)
      .typeError(VENDOR_LICENSE_ERROR)
      .trim()
      .required(VENDOR_LICENSE_ERROR),
    otherwise: Yup.string().nullable()
  })
});

const FishLostSchema = Yup.object().shape({
  species_id: Yup.number(SPECIES_ERROR)
    .typeError(SPECIES_ERROR)
    .positive(SPECIES_ERROR)
    .integer(SPECIES_ERROR)
    .required(SPECIES_ERROR),
  total_count_lost: Yup.number(COUNT_ERROR)
    .typeError(COUNT_ERROR)
    .positive(COUNT_ERROR)
    .integer(COUNT_ERROR),
  total_weight_lbs_lost: Yup.number(WEIGHT_ERROR)
    .typeError(WEIGHT_ERROR)
    .positive(WEIGHT_ERROR)
    .nullable(),
  lost_to_predator: Yup.boolean().typeError(LOST_TO_PREDATOR_ERROR),
  predator_species_id: Yup.number(PREDATOR_SPECIES_ERROR).when(
    "lost_to_predator",
    {
      is: true,
      then: Yup.number(PREDATOR_SPECIES_ERROR)
        .typeError(PREDATOR_SPECIES_ERROR)
        .positive(PREDATOR_SPECIES_ERROR)
        .integer(PREDATOR_SPECIES_ERROR)
        .required(PREDATOR_SPECIES_ERROR),
      otherwise: Yup.number(PREDATOR_SPECIES_ERROR)
        .typeError(PREDATOR_SPECIES_ERROR)
        .positive(PREDATOR_SPECIES_ERROR)
        .integer(PREDATOR_SPECIES_ERROR)
        .nullable()
    }
  ),
  disposition: Yup.string(DISPOSITION_TYPE_ERROR).when("lost_to_predator", {
    is: false,
    then: Yup.string(DISPOSITION_TYPE_ERROR)
      .typeError(DISPOSITION_TYPE_ERROR)
      .required(DISPOSITION_TYPE_ERROR),
    otherwise: Yup.string(DISPOSITION_TYPE_ERROR)
      .typeError(DISPOSITION_TYPE_ERROR)
      .nullable()
  })
});

const Step1Schema = Yup.object().shape({
  departure_datetime: Yup.date().required(DEPARTURE_TIME_ERROR),
  departure_location: Yup.string().required(DEPARTURE_LOCATION_ERROR),
  is_charter: Yup.boolean().required(CHARTER_ERROR),
  vessel_license_number: Yup.string(VESSEL_LICENSE_ERROR).nullable()
});

const Step2Schema = Yup.object().shape({
  crew_member: Yup.array().of(CrewMemberSchema)
});

const Step3Schema = Yup.object().shape({
  fishing_events: Yup.array().of(FishingEventSchema)
});

const Step4Schema = Yup.object().shape({
  fish_lost: Yup.array().of(FishLostSchema)
});

const Step5Schema = Yup.object().shape({
  arrival_datetime: Yup.date().required(ARRIVAL_TIME_ERROR),
  arrival_location: Yup.string().required(ARRIVAL_LOCATION_ERROR)
});

const SalesOrderSchema = Yup.object().shape({
  items: Yup.array().of(SalesOrderItemsSchema).ensure()
});

const Step6Schema = Yup.object().shape({
  sales_order: Yup.array().of(SalesOrderSchema)
});

const Step7Schema = Yup.object().shape({
  arrival_datetime: Yup.date()
    .typeError(ARRIVAL_TIME_ERROR)
    .required(ARRIVAL_TIME_ERROR),
  arrival_location: Yup.string()
    .typeError(ARRIVAL_LOCATION_ERROR)
    .required(ARRIVAL_LOCATION_ERROR),
  departure_datetime: Yup.date()
    .typeError(DEPARTURE_TIME_ERROR)
    .required(DEPARTURE_TIME_ERROR),
  departure_location: Yup.string()
    .typeError(DEPARTURE_LOCATION_ERROR)
    .required(DEPARTURE_LOCATION_ERROR),
  vessel_license_number: Yup.string()
    .typeError(VESSEL_LICENSE_ERROR)
    .nullable(),
  is_charter: Yup.boolean().typeError(CHARTER_ERROR).required(CHARTER_ERROR),
  fishing_events: Yup.array().of(FishingEventSchema),
  sales_order: Yup.array().of(Step6Schema),
  crew_member: Yup.array().of(CrewMemberSchema)
});

export const SchemaValidation = [
  Step1Schema,
  Step2Schema,
  Step3Schema,
  Step4Schema,
  Step5Schema,
  Step6Schema,
  Step7Schema
];

export const formProgressWidthUtil = formValues => {
  if (formValues.sales_order.length && formValues.sales_order[0].items.length)
    return "100%";
  if (formValues.fishing_events.length) return "75%";
  if (formValues.crew_member.length > 1) return "50%";
  if (formValues.departure_location) return "25%";
  return "0%";
};

export const formProgressStepUtil = formValues => {
  if (formValues.sales_order.length && formValues.sales_order[0].items.length)
    return 4;
  if (formValues.fishing_events.length) return 3;
  if (formValues.crew_member.length > 1) return 2;
  return 1;
};

export const totalPoundsFromEvents = fishingEvents => {
  return fishingEvents
    .map(fishingEvent => {
      return totalPoundsFromEvent(fishingEvent);
    })
    .reduce((eventTotalWeight, catchTotalWeight) => {
      return eventTotalWeight + catchTotalWeight;
    }, 0);
};

export const totalPoundsFromEvent = fishingEvent => {
  return fishingEvent.catches.reduce((totalWeight, fishingCatch) => {
    return totalWeight + parseFloat(fishingCatch.total_weight_lbs);
  }, 0);
};

export const speciesListFromEvent = (fishingEvent, species) => {
  const completedFishSpeciesList = fishingEvent.catches.reduce(
    (speciesList, fishingCatch) => {
      if (!fishingCatch.species_id) {
        return speciesList;
      }
      const commonName = species.find(
        spec => spec.id == fishingCatch.species_id
      ).common_name;
      if (speciesList.includes(commonName)) {
        return speciesList;
      }
      if (speciesList.length === 0) {
        return commonName;
      }
      return speciesList + `, ${commonName}`;
    },
    ""
  );
  if (completedFishSpeciesList.length) return completedFishSpeciesList;
  return "No species";
};

export const groupedFishingDetailsFromEvents = fishingEvents => {
  if (fishingEvents.length === 0) {
    return [
      {
        total_count: 0,
        total_weight_lbs: 0
      }
    ];
  }

  const groupedBySpecies = groupBy(
    fishingEvents
      .map(fishingEvent => {
        return fishingEvent.catches;
      })
      .flat(),
    `species_id`
  );
  Object.keys(groupedBySpecies).forEach(speciesId => {
    groupedBySpecies[`${speciesId}`] = groupedBySpecies[`${speciesId}`].reduce(
      (cumm, currentObject) => {
        return {
          total_weight_lbs:
            cumm.total_weight_lbs +
            Number.parseFloat(currentObject.total_weight_lbs),
          total_count:
            cumm.total_count + Number.parseInt(currentObject.total_count)
        };
      },
      {
        total_count: 0,
        total_weight_lbs: 0
      }
    );
  });
  return groupedBySpecies;
};

export const totalsFromSales = saleItems => {
  const groupedBySpecies = groupBy(saleItems.flat(), `species_id`);
  Object.keys(groupedBySpecies).forEach(speciesId => {
    groupedBySpecies[`${speciesId}`] = groupedBySpecies[`${speciesId}`].reduce(
      (cumm, currentObject) => {
        return {
          total_weight_lbs:
            cumm.total_weight_lbs +
            Number.parseFloat(currentObject.total_weight_lbs),
          total_count:
            cumm.total_count + Number.parseInt(currentObject.total_count)
        };
      },
      {
        total_count: 0,
        total_weight_lbs: 0
      }
    );
  });
  return groupedBySpecies;
};

export const formatFloat = num => numeral(Math.abs(num)).format("0.00");
