import { SET_APPLICATION_SETTINGS } from "../actions/settings";

export const DEFAULT_SETTING_STATE = {
  // Fallback to global map view
  default_map_options: {
    latitude: 0,
    longitude: 0,
    zoom_level: 0
  }
};

export default function settingsReducer(
  state = DEFAULT_SETTING_STATE,
  action = {}
) {
  switch (action.type) {
    case SET_APPLICATION_SETTINGS:
      return action.settings;
    default:
      return state;
  }
}
