import * as Yup from "yup";

export const LengthError = "Must contain at least 10 digits.";
export const SymbolError = "Must contain at least one symbol.";
export const DigitError = "Must contain at least one digit.";
export const LowerCaseError = "Must contain at least one lowercase character.";
export const UpperCaseError = "Must contain at least one uppercase character.";

export const password = Yup.string()
  .test("validatePassword", validatePassword)
  .test("Password must be provided.", function (password) {
    const { path, createError } = this;
    const { re_entered_password } = this.parent;

    if (!password && re_entered_password) {
      return createError({
        path,
        message: "Password must be provided."
      });
    }

    return true;
  });
export const re_entered_password = Yup.string().test(
  "Reenter Password must be provided",
  function (re_entered_password) {
    const { path, createError } = this;
    const { password } = this.parent;

    if (password && !re_entered_password) {
      return createError({
        path,
        message: "Reenter Password must be provided."
      });
    }

    if (password && password !== re_entered_password) {
      return createError({
        path,
        message: "Must match password."
      });
    }

    return true;
  }
);

export function isValidPassword(password) {
  if (password === undefined) return true;

  const symbolRegExp = /[!-/:-@[-`{-~]/;
  const digitRegExp = /\d/;
  const lowercaseCharacterRegExp = /[a-z]/;
  const uppercaseCharacterRegExp = /[A-Z]/;

  const errors = [];

  if (!symbolRegExp.test(password)) {
    errors.push(SymbolError);
  }
  if (!digitRegExp.test(password)) {
    errors.push(DigitError);
  }
  if (!lowercaseCharacterRegExp.test(password)) {
    errors.push(LowerCaseError);
  }
  if (!uppercaseCharacterRegExp.test(password)) {
    errors.push(UpperCaseError);
  }

  let failureMessages = [];

  if (password.length < 10) {
    return [LengthError, ...errors];
  }

  if (errors.length > 1) {
    return errors;
  }

  return [];
}

export function validatePassword(password) {
  const errors = isValidPassword(password);

  if (errors.length > 0) {
    const { path, createError } = this;

    let failureMessage = errors.reduce(
      (message, error) => message + error + ";", //delimit multiple errors with ';'
      ""
    );

    failureMessage = failureMessage.substring(0, failureMessage.length - 1); //remove last ;

    return createError({ path, message: failureMessage });
  }

  return true;
}
