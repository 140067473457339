import React from "react";
import { Field } from "formik";

import { ErrorMessage, FormControl, InputLabel, Input } from "../";

const TextField = ({ id, label, type, placeholder, name, ...props }) => {
  return (
    <Field
      name={name}
      render={({ field, form: { touched, errors } }) => {
        let errorMessages = [];

        if (touched[field.name] && errors[field.name]) {
          errorMessages = errors[field.name]
            .split(";")
            .map((message, i) => (
              <ErrorMessage key={`${field.name}-error-${i}`}>
                {message}
              </ErrorMessage>
            ));
        }

        return (
          <FormControl htmlFor={id}>
            {label && <InputLabel>{label}</InputLabel>}
            <Input
              id={id}
              type={type}
              placeholder={placeholder}
              {...field}
              {...props}
            />
            {errorMessages}
          </FormControl>
        );
      }}
    />
  );
};
export default TextField;
