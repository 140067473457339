import { GET_PORTS } from "../actions/ports";

const INITIAL_PORTS_STATE = [];

export default function portsReducer(state = INITIAL_PORTS_STATE, action = {}) {
  switch (action.type) {
    case GET_PORTS:
      return action.payload.ports;
    default:
      return state;
  }
}
