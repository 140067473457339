import React from "react";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  container: {
    color: theme.palette.common.black,
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: "0.875rem",
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    lineHeight: 1,
    fontWeight: 400,
    textAlign: "left",
    padding: 0
  }
});

const SelectLabel = ({ classes, children }) => {
  return <label className={classes.container}>{children}</label>;
};

export default withStyles(styles)(SelectLabel);
