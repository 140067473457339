import { GET_VENDOR_LICENSES } from "../actions/vendors";

const INITIAL_VENDOR_LICENSES_STATE = [];

export default function vendorsReducer(
  state = INITIAL_VENDOR_LICENSES_STATE,
  action = {}
) {
  switch (action.type) {
    case GET_VENDOR_LICENSES:
      return action.payload.vendor_licenses;
    default:
      return state;
  }
}
