import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { applyMiddleware, createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import moment from "moment";

import rootReducer from "./reducers/";

const middlewares = [thunk, logger];

const MomentTransform = createTransform(
  (inboundState, key) => {
    return inboundState;
  },
  (outboundState, key) => {
    const approvedReports = outboundState.approvedReports.map(report => {
      return {
        ...report,
        submitted_at: report.submitted_at ? moment(report.submitted_at) : null
      };
    });

    return {
      ...outboundState,
      startDate: moment(outboundState.startDate),
      approvedReports
    };
  },
  {
    whitelist: ["dashboard"]
  }
);

const persistConfig = {
  key: "root",
  storage,
  transforms: [MomentTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
