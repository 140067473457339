import React from "react";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";

const styles = theme => ({
  cardBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: "100%"
  }
});

const CardBody = ({ classes, className, children, ...rest }) => {
  const cardBodyClasses = cn({
    [classes.cardBody]: true,
    [className]: className !== undefined
  });
  return (
    <div {...rest} className={cardBodyClasses}>
      {children}
    </div>
  );
};

export default withStyles(styles)(CardBody);
