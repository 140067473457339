import { westpac } from "../services";

export const GET_PORTS = "GET_PORTS";

export const getPorts = () => {
  return dispatch => {
    return westpac.get("/ports").then(({ data }) => {
      const { ports } = data;
      dispatch({ type: GET_PORTS, payload: { ports } });
    });
  };
};
