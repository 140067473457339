import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import {
  Card,
  Container,
  Divider,
  Typography,
  GridContainer,
  GridItem,
  DashboardMap
} from "../../components";
import { Bar, Pie, Progress } from "@sudokrew/wespac-components";
import { PeriodTabs, Tab } from "./PeriodTabs";
import BoardHeader from "./BoardHeader";
import SpeciesTable from "./SpeciesTable";
import aggregator from "./aggregator";
import peek from "./peek";
import {
  updatePeriod,
  incrementStartDate,
  decrementStartDate
} from "../../actions/dashboard";
import { findQuarter, QUARTERS } from "./utils";
import {
  VictoryContainer as GraphContainer,
  VictoryChart,
  VictoryAxis,
  VictoryLegend as Legend,
  VictoryStack
} from "victory";

const fishingMethodThemeColor = {
  Bottomfishing: "#56AE00",
  Spearfishing: "#0D7674",
  Trolling: "#0096CB"
};

const PROGRESS_SWATCHES = {
  Bottomfishing: ["#6DAB32", "#E0EECF", "#586947"],
  Trolling: ["#4195C6", "#D3E3E3", "#4C6371"],
  Spearfishing: ["#357473", "#D2E9F4", "#485A5A"]
};

const styles = theme => ({
  boardBody: {
    padding: `0px ${theme.spacing(1)}px`
  },
  cardBody: {
    marginTop: `${theme.spacing(2)}px`,
    padding: `${theme.spacing(1)}px`
  },
  innerCard: {
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px 0`
  },
  boxBorderStyles: {
    border: "1px solid #D8D8D8",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
    padding: "2rem 0"
  },
  totalsContainer: { padding: `${theme.spacing(1)}px 0px` }
});

/**
 * @param {number} number
 */
const toPercentage = number => {
  if (number == null) {
    return "";
  }

  return number.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0
  });
};

function formatPlacement(placement) {
  if (placement == undefined) {
    return "–";
  }
  return moment.localeData().ordinal(placement);
}

function renderSpeciesTables(table) {
  let entries = [];
  Object.keys(table).forEach(key => {
    entries = [...entries, ...table[key]];
  });

  return <SpeciesTable rows={entries} />;
}

function formatDate(date, period) {
  if (period === "year") {
    return date.year();
  }

  if (period === "quarter") {
    const quarter = findQuarter(date.month());
    const monthsInQuarter = QUARTERS[quarter];
    const beginning = moment({ month: monthsInQuarter[0] }).format("MMM");
    const end = moment({ month: monthsInQuarter[2] }).format("MMM");
    const year = date.year();

    return `${beginning}-${end} ${year}`;
  }

  if (period === "month") {
    return `${date.format("MMM")} ${date.year()}`;
  }
}

function findSpeciesName(speciesList, speciesId) {
  const speciesFound = speciesList.find(fish => fish.id == speciesId);
  if (speciesFound) return speciesFound.common_name;
  return "-";
}

function renderPieChart(options) {
  return (
    <Box
      component="div"
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Pie
        width={120}
        height={120}
        radius={60}
        containerComponent={
          <GraphContainer responsive={false} height={120} width={120} />
        }
        data={Object.keys(options.catchCount).map(c => {
          return {
            x: c,
            y: options.catchCount[c]
          };
        })}
        style={{
          data: {
            fill: ({ datum }) => {
              switch (datum.x) {
                case "Bottomfishing":
                  return "#56AE00";
                case "Spearfishing":
                  return "#0D7674";
                case "Trolling":
                default:
                  return "#0096CB";
              }
            }
          }
        }}
      />
      <Legend
        width={150}
        height={100}
        containerComponent={
          <GraphContainer responsive={false} style={{ paddingTop: "1rem" }} />
        }
        orientation="vertical"
        rowGutter={-5}
        data={[
          {
            name: `${toPercentage(
              options.catchCount["Spearfishing"]
            )} Spearfishing`,
            symbol: { fill: "#0D7674", type: "square" }
          },
          {
            name: `${toPercentage(options.catchCount["Trolling"])} Trolling`,
            symbol: { fill: "#0096CB", type: "square" }
          },
          {
            name: `${toPercentage(
              options.catchCount["Bottomfishing"]
            )} Bottomfishing`,
            symbol: { fill: "#56AE00", type: "square" }
          }
        ]}
      />
    </Box>
  );
}

const percentAboveMarketValue = (metricsBySpeciesOfFish, totalPoundSold) => {
  let poundsAboveMP = 0;
  metricsBySpeciesOfFish.forEach(fish => {
    if (fish.avg_price_per_lb > fish.market_avg) {
      poundsAboveMP += fish.total_lbs;
    }
  });
  if (!!poundsAboveMP / totalPoundSold) {
    return `${((poundsAboveMP / totalPoundSold) * 100).toFixed(0)}%`;
  }
  return "-";
};

const Dashboard = ({
  approvedReports,
  classes,
  updatePeriod,
  incrementStartDate,
  decrementStartDate,
  period,
  startDate,
  speciesInfo,
  marketMetrics,
  mapAreas,
  settings,
  ...rest
}) => {
  const {
    totalPoundsCaught,
    totalSales,
    totalPoundsSold,
    metricsBySpeciesOfFish,
    totalTripCount,
    fishedMapAreaIds,
    speciesCatchesByFishingEvent,
    caughtPoundsByMethodMonthly,
    lastYearsCatchTotals
  } = aggregator(
    approvedReports,
    startDate,
    period,
    marketMetrics.average_market_prices,
    marketMetrics.last_years_catch_totals,
    mapAreas,
    speciesInfo
  );

  const USER_DASHBOARD = "USER_DASHBOARD";
  const COMMUNITY_DASHBOARD = "COMMUNITY_DASHBOARD";
  const speciesTables = metricsBySpeciesOfFish.reduce((table, species) => {
    const foundSpecies = speciesInfo.find(
      info => info.id == species.id
    );

    if(foundSpecies) {
      const { category, common_name } = foundSpecies;
      if (table.hasOwnProperty(category)) {
        table[category].push({ ...species, common_name });
      } else {
        table[category] = [{ ...species, common_name }];
      }
    }

    return table;
  }, {});

  const canMoveForward = peek.forward(approvedReports, startDate, period);
  const canMoveBackwards = peek.backward(approvedReports, startDate, period);

  const formattedDate = formatDate(startDate, period);

  const [activeTab, setActiveTab] = useState(COMMUNITY_DASHBOARD);

  const pinCoordinates = [
    settings.default_map_options.latitude,
    settings.default_map_options.longitude
  ];

  const [defaultMapCenter, setDefaultMapCenter] = useState(pinCoordinates);
  const [mapPinCoordinates, setMapPinCoordinates] = useState(pinCoordinates);
  // Update the pin coordinates if the settings object changes
  useEffect(() => {
    const pinCoordinates = [
      settings.default_map_options.latitude,
      settings.default_map_options.longitude
    ];
    setDefaultMapCenter(pinCoordinates);
    setMapPinCoordinates(pinCoordinates);
  }, [settings]);

  const topSpeciesByWeight = [...metricsBySpeciesOfFish].sort(
    (a, b) => b.total_catch_event_weight_lbs - a.total_catch_event_weight_lbs
  );
  if (topSpeciesByWeight.length > 5) {
    // Shortens the list of top 5 species caught
    topSpeciesByWeight.length = 5;
  }

  const topSpeciesProfitMargin = [...metricsBySpeciesOfFish].sort((a, b) => {
    const aProfitMargin = a.avg_price_per_lb - a.market_avg;
    const bProfitMargin = b.avg_price_per_lb - b.market_avg;
    return bProfitMargin - aProfitMargin;
  });
  if (topSpeciesProfitMargin.length > 5) {
    // Shortens the list of top 5 species caught
    topSpeciesProfitMargin.length = 5;
  }

  const catchTotalsByFishingMethod = {
    Bottomfishing: 0,
    Spearfishing: 0,
    Trolling: 0,
    Total: 0
  };

  if (marketMetrics) {
    marketMetrics.reported_catches.forEach(catchMetricByMethod => {
      if (catchMetricByMethod.fishing_method === null) {
        catchTotalsByFishingMethod.Total =
          catchMetricByMethod.species_catch_totals[
            catchMetricByMethod.species_catch_totals.length - 1
          ].total_weight_lbs;
      } else {
        catchTotalsByFishingMethod[catchMetricByMethod.fishing_method.type] =
          catchMetricByMethod.species_catch_totals[
            catchMetricByMethod.species_catch_totals.length - 1
          ].total_weight_lbs;
      }
    });
  }

  const lastYearCatchTotals = {
    Bottomfishing: lastYearsCatchTotals.Bottomfishing,
    Spearfishing: lastYearsCatchTotals.Spearfishing,
    Trolling: lastYearsCatchTotals.Trolling
  };

  const catchCountPercentages = {
    Bottomfishing:
      catchTotalsByFishingMethod.Bottomfishing /
      catchTotalsByFishingMethod.Total,
    Spearfishing:
      catchTotalsByFishingMethod.Spearfishing /
      catchTotalsByFishingMethod.Total,
    Trolling:
      catchTotalsByFishingMethod.Trolling / catchTotalsByFishingMethod.Total
  };

  return (
    <Container>
      <PeriodTabs style={{ marginBottom: "1rem" }}>
        <Tab
          active={activeTab === COMMUNITY_DASHBOARD}
          onClick={() => setActiveTab(COMMUNITY_DASHBOARD)}
        >
          Community Dashboard
        </Tab>
        <Tab
          active={activeTab === USER_DASHBOARD}
          onClick={() => setActiveTab(USER_DASHBOARD)}
        >
          My Dashboard
        </Tab>
      </PeriodTabs>
      {activeTab === COMMUNITY_DASHBOARD && (
        <GridContainer justify="center" alignItems="stretch">
          <GridItem
            xs={7}
            className={classes.boxBorderStyles}
            style={{
              textAlign: "left",
              paddingLeft: "1rem",
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "space-evenly"
            }}
          >
            <Typography variant="h5">
              <strong>
                {formatNumberToString(catchTotalsByFishingMethod.Total)}
              </strong>
            </Typography>
            <Typography variant="p">{`Total Pounds Caught for ${moment().format(
              "YYYY"
            )}`}</Typography>

            <Divider style={{ margin: "1rem 0 1rem -1rem" }} />
            <Typography variant="h5">Total Pounds Caught</Typography>
            <dl>
              {[
                [
                  formatNumberToString(
                    catchTotalsByFishingMethod.Bottomfishing
                  ),
                  "Bottomfishing"
                ],
                [
                  formatNumberToString(catchTotalsByFishingMethod.Trolling),
                  "Trolling"
                ],
                [
                  formatNumberToString(catchTotalsByFishingMethod.Spearfishing),
                  "Spearfishing"
                ]
              ].map(d => {
                return (
                  <>
                    <dt>
                      <strong>{d[0]}</strong>
                    </dt>
                    <dd>{d[1]}</dd>
                  </>
                );
              })}
            </dl>
          </GridItem>
          <GridItem
            xs={5}
            className={classes.boxBorderStyles}
            style={{ textAlign: "center" }}
          >
            {renderPieChart({
              catchCount: catchCountPercentages
            })}
          </GridItem>
          <p style={{ width: "100%", padding: "0 0.5rem" }}>
            <span style={{ fontSize: 20, fontWeight: 300 }}>
              Total Pounds Caught Year to Date
            </span>
          </p>
          <React.Fragment>
            {Object.entries(lastYearCatchTotals).map(
              ([methodName, lastYearsTotal]) => {
                const currentYearsTotal =
                  catchTotalsByFishingMethod[methodName];
                return (
                  <GridItem
                    key={`${methodName}_${lastYearsTotal}`}
                    xs={12}
                    className={classes.boxBorderStyles}
                    style={{
                      padding: "1rem",
                      marginBottom: "1rem",
                      display: "flex"
                    }}
                  >
                    <div
                      style={{
                        marginRight: "0.75rem",
                        alignSelf: "center",
                        flex: "0 0 110px"
                      }}
                    >
                      <span style={{ fontSize: 19, fontWeight: 300 }}>
                        {methodName}
                      </span>
                    </div>
                    <div>
                      <div>
                        <Progress
                          value={currentYearsTotal}
                          max={lastYearsTotal}
                          colorScale={PROGRESS_SWATCHES[methodName]}
                        ></Progress>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <div style={{ textAlign: "left" }}>
                          <strong>
                            {formatNumberToString(currentYearsTotal)}
                          </strong>
                          <br />
                          Caught
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <strong>
                            {formatNumberToString(lastYearsTotal)}
                          </strong>
                          <br />
                          Last year's total
                        </div>
                      </div>
                    </div>
                  </GridItem>
                );
              }
            )}
          </React.Fragment>
        </GridContainer>
      )}
      {activeTab === USER_DASHBOARD && (
        <>
          <Container>
            <Card className={classes.board}>
              <Card
                className={classes.innerCard}
                style={{ paddingBottom: "2rem" }}
              >
                <PeriodTabs style={{ margin: "1rem" }}>
                  <Tab
                    active={period === "month"}
                    onClick={() => updatePeriod("month")}
                  >
                    Month
                  </Tab>
                  <Tab
                    active={period === "quarter"}
                    onClick={() => updatePeriod("quarter")}
                  >
                    Quarter
                  </Tab>
                  <Tab
                    active={period === "year"}
                    onClick={() => updatePeriod("year")}
                  >
                    Year
                  </Tab>
                </PeriodTabs>
                <BoardHeader
                  text={formattedDate}
                  onLeftArrowClick={() => decrementStartDate(startDate, period)}
                  onRightArrowClick={() =>
                    incrementStartDate(startDate, period)
                  }
                  hideLeftArrow={!canMoveBackwards}
                  hideRightArrow={!canMoveForward}
                />
                <GridContainer justify="space-evenly" spacing={3}>
                  <GridItem style={{ textAlign: "center" }}>
                    <Typography variant="h5">
                      {formatNumberToString(totalTripCount)}
                    </Typography>
                    Trips Reported
                  </GridItem>
                  <GridItem style={{ textAlign: "center" }}>
                    <Typography variant="h5">
                      {formatNumberToString(totalPoundsCaught)}
                    </Typography>
                    Pounds Caught
                  </GridItem>
                  <GridItem style={{ textAlign: "center" }}>
                    <Typography variant="h5">
                      {`$${formatNumberToString(totalSales)}`}
                    </Typography>
                    Earned from Sales
                  </GridItem>
                </GridContainer>
              </Card>

              <Card className={classes.innerCard}>
                <div
                  className={classes.cardBody}
                  style={{ margin: "0 0 1rem 0" }}
                >
                  <Typography variant="h5" style={{ margin: "1rem 0" }}>
                    Catches By Area
                  </Typography>
                  <DashboardMap
                    defaultMapCenter={defaultMapCenter}
                    fishedMapAreaIds={fishedMapAreaIds}
                  />
                </div>
              </Card>

              <Card className={classes.innerCard}>
                <div className={classes.cardBody}>
                  <Typography variant="h5">Catches Per Month</Typography>
                  <VictoryChart domainPadding={{ x: 15 }} height={250}>
                    {caughtPoundsByMethodMonthly.map(
                      (monthlyCatch, monthlyCatchIndex) => {
                        return (
                          <VictoryStack
                            key={`stack-by-month-${monthlyCatchIndex}`}
                          >
                            {Object.keys(monthlyCatch.values).map(
                              (fishingMethod, fishingMethodIndex) => {
                                return (
                                  <Bar
                                    key={`catch-by-method-bar-${monthlyCatchIndex}-${fishingMethodIndex}`}
                                    barRatio={3}
                                    style={{
                                      data: {
                                        fill: `${fishingMethodThemeColor[fishingMethod]}`
                                      }
                                    }}
                                    data={[
                                      {
                                        x: monthlyCatch.label,
                                        y: monthlyCatch.values[fishingMethod]
                                      }
                                    ]}
                                  />
                                );
                              }
                            )}
                          </VictoryStack>
                        );
                      }
                    )}
                    <Legend
                      x={60}
                      y={225}
                      orientation="horizontal"
                      colorScale={[
                        `${fishingMethodThemeColor.Bottomfishing}`,
                        `${fishingMethodThemeColor.Spearfishing}`,
                        `${fishingMethodThemeColor.Trolling}`
                      ]}
                      data={[
                        { name: "Bottomfishing" },
                        { name: "Spearfishing" },
                        { name: "Trolling" }
                      ]}
                    />
                  </VictoryChart>
                </div>
              </Card>

              <Card className={classes.innerCard}>
                <div className={classes.cardBody}>
                  <Typography variant="h5" style={{ margin: "1rem 0 0 0" }}>
                    Most Caught Species by Pound
                  </Typography>
                  <VictoryChart
                    domainPadding={{ x: 15 }}
                    padding={{ top: 40, bottom: 40, left: 120, right: 40 }}
                    height={250}
                  >
                    <VictoryAxis
                      dependentAxis
                      style={{
                        grid: { stroke: "grey" },
                        tickLabels: {
                          fontWeight: 100,
                          fontSize: 11,
                          padding: 7
                        }
                      }}
                    />
                    <VictoryAxis
                      style={{
                        tickLabels: {
                          fontWeight: 100,
                          fontSize: 13,
                          lineHeight: 29,
                          padding: 11
                        }
                      }}
                    />
                    {topSpeciesByWeight
                      .reverse()
                      .map((topSpecies, topSpeciesIndex) => {
                        return (
                          <Bar
                            key={`top-species-bar-${topSpeciesIndex}`}
                            horizontal
                            barRatio={3}
                            style={{ data: { fill: "#56AE00" } }}
                            data={[
                              {
                                x: findSpeciesName(speciesInfo, topSpecies.id),
                                y: topSpecies.total_catch_event_weight_lbs
                              }
                            ].filter(entry => entry.y > 0)}
                          />
                        );
                      })}
                  </VictoryChart>
                </div>
              </Card>

              <div className={classes.cardBody}>
                <div>
                  <div
                    xs={4}
                    style={{ padding: "1rem", marginBottom: "1.5rem" }}
                    className={classes.boxBorderStyles}
                  >
                    <GridContainer justify="space-between">
                      <GridItem xs={5}>
                        <Typography
                          variant="h5"
                          style={{ fontSize: "2rem", fontWeight: "700" }}
                        >
                          {percentAboveMarketValue(
                            metricsBySpeciesOfFish,
                            totalPoundsSold
                          )}
                        </Typography>
                        <Typography variant="body1">
                          Of Catches Sold Above MP
                        </Typography>
                      </GridItem>
                      <GridItem xs={5}>
                        <Typography
                          variant="h5"
                          style={{ fontSize: "2rem", fontWeight: "700" }}
                        >
                          {formatPlacement(marketMetrics.placement)}
                        </Typography>
                        <Typography variant="body1">
                          Most Pounds Reported This Week
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div
                    xs={8}
                    style={{ padding: "1rem" }}
                    className={classes.boxBorderStyles}
                  >
                    <Typography variant="h5">
                      Best Average Price Per Pound
                    </Typography>
                    {topSpeciesProfitMargin.map((species, speciesIndex) => {
                      return (
                        <GridContainer key={`species-index${speciesIndex}`}>
                          <GridItem xs={3}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "700" }}
                            >
                              ${species.avg_price_per_lb.toFixed(2)}{" "}
                            </Typography>
                          </GridItem>
                          <GridItem xs={8}>
                            <Typography variant="body1">
                              {findSpeciesName(speciesInfo, species.id)}
                            </Typography>
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </div>
                </div>
              </div>

              <br />
              <div>
                <div className={classes.totalsContainer}></div>
                <Divider />
                {metricsBySpeciesOfFish.length > 0 && (
                  <div className={classes.cardBody}>
                    <h5>
                      <Typography align="center" variant="subtitle2">
                        Sales By Species of Fish
                      </Typography>
                    </h5>
                    {renderSpeciesTables(speciesTables)}
                  </div>
                )}
              </div>
            </Card>
          </Container>
        </>
      )}
    </Container>
  );
};

export const SidebarMetricItem = props => {
  let { value } = props;
  return (
    <div className="sidebar_metrics__container" style={props.style}>
      <strong className="sidebar_metrics__value">{value}</strong>
      <span className="sidebar_metrics__label">{props.label}</span>
    </div>
  );
};

/**
 *
 * @param {number | null} number
 */
function formatNumberToString(number) {
  if (number == null) {
    return "-";
  }

  return number.toLocaleString();
}

const StyledDashboard = withStyles(styles)(Dashboard);

const mapStateToProps = state => ({
  period: state.dashboard.period,
  startDate: state.dashboard.startDate,
  approvedReports: state.dashboard.approvedReports,
  speciesInfo: state.species,
  marketMetrics: state.marketMetrics,
  mapAreas: state.mapAreas.mapAreas,
  settings: state.settings
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePeriod,
      incrementStartDate,
      decrementStartDate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StyledDashboard);
