import { westpac } from "../services";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const GET_ACCOUNT = "GET_ACCOUNT";

export const getAccountInfo = () => {
  return dispatch => {
    dispatch({ type: GET_ACCOUNT });

    return westpac.get("/user").then(({ data }) => {
      dispatch(
        updateAccount({
          business_license_number: data.business_license_number,
          vessel_license_number: data.vessel_license_number,
          first_name: data.first_name || "",
          middle_name: data.middle_name || "",
          last_name: data.last_name || "",
          contact_information_address_street:
            data.contact_information_address_street || "",
          contact_information_address_street_secondary:
            data.contact_information_address_street_secondary || "",
          contact_information_address_city:
            data.contact_information_address_city || "",
          contact_information_address_postal_code:
            data.contact_information_address_postal_code || "",
          contact_information_email_address:
            data.contact_information_email_address || "",
          contact_information_phone_number:
            data.contact_information_phone_number || ""
        })
      );
    });
  };
};

export const updateAccount = values => ({
  type: UPDATE_ACCOUNT,
  payload: {
    account: {
      ...values
    }
  }
});
