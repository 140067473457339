export const QUARTERS = {
  Q1: [0, 1, 2],
  Q2: [3, 4, 5],
  Q3: [6, 7, 8],
  Q4: [9, 10, 11]
};
/**
 *
 * @param {Number} month - A 0 zero-indexed number represnting the month of the quarter.
 *
 */
export function findQuarter(month) {
  const quarters = Object.entries(QUARTERS);

  for (const [quarter, months] of quarters) {
    if (months.includes(month)) {
      return quarter;
    }
  }

  return null;
}
