import { westpac } from "../services";

export const GET_MAP_AREAS = "GET_MAP_AREAS";

export const fetchMapArea = async url => {
  const cache = await caches.open("map-areas");
  try {
    const area = await (await fetch(url)).json();
    cache.add(url);
    return area;
  } catch (err) {
    const cachedMapRequest = await cache.match(url);
    return await cachedMapRequest.json();
  }
};

export const getMapAreas = () => {
  return dispatch => {
    return westpac.get("/map-areas").then(async ({ data }) => {
      const { map_areas } = data;

      const activeMapArea = map_areas.find(mapArea => !!mapArea.active);

      await Promise.all(
        map_areas.map(mapArea => {
          return fetchMapArea(mapArea.file_url);
        })
      );

      dispatch({
        type: GET_MAP_AREAS,
        payload: { mapAreas: map_areas, activeMapArea }
      });
    });
  };
};
