import { v4 as uuidv4 } from "uuid";

/**
 * Convert the uploaded photo to Base64 encoded string
 *
 * @param {FileList} fileList - Array of image file uploads
 * @return {string} The uploaded image as a Base64 encoded string
 */
export const parseFishingCatchPhotoUpload = async fileList => {
  const uploads = Object.keys(fileList).map(fileIndex => fileList[fileIndex]);

  return await Promise.all(
    uploads.map(async file => {
      if (!file || !file.type.match("image.*")) {
        throw new Error("There was a problem uploading your photo.");
      }

      if (file.size > 10000000) {
        throw new Error("Image size must not exceed 10mb.");
      }

      const reader = new FileReader();
      await new Promise(resolve => {
        reader.readAsDataURL(file);
        reader.onloadend = resolve;
      });

      return {
        id: uuidv4(),
        mime_type: file.type,
        base64: reader.result
      };
    })
  );
};
