import React from "react";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";
import PropTypes from "prop-types";

const styles = theme => ({
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white
  },
  container: {
    padding: 0
  }
});

const Card = ({ classes, className, container, children, ...rest }) => {
  const cardClasses = cn({
    [classes.card]: true,
    [classes.container]: container,
    [className]: className !== undefined
  });

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

Card.propTypes = {
  container: PropTypes.bool
};

export default withStyles(styles)(Card);
