import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "@sudokrew/wespac-components";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import { connect } from "react-redux";

function DynamicTooltip(props) {
  const FIELD_NAME = props.field;
  const fields = new Map(props.fields);

  if (!fields.has(FIELD_NAME)) {
    return null;
  }

  return (
    <Tooltip title={fields.get(FIELD_NAME)}>
      <InfoOutlined style={{ verticalAlign: "text-bottom" }} />
    </Tooltip>
  );
}

DynamicTooltip.propTypes = {
  field: PropTypes.string.isRequired
};

DynamicTooltip.defaultProps = {
  fields: []
};

const mapStateToProps = (state, ownProps) => {
  return {
    field: ownProps.field,
    fields: state.fields
  };
};

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(DynamicTooltip);
