import * as React from "react";
import MuiInputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/styles";
import { ScaleControl } from "react-leaflet";

const styles = theme => ({
  root: {
    color: theme.palette.common.black,
    fontSize: "0.875rem",
    position: "relative",
    transform: "translate(0, -5px) scale(1)",
    whiteSpace: "nowrap"
  }
});

const InputLabel = ({ classes, children, shrink = false, ...rest }) => {
  return (
    <MuiInputLabel classes={classes} shrink={shrink} {...rest}>
      {children}
    </MuiInputLabel>
  );
};

export default withStyles(styles)(InputLabel);
