import React from "react";
import MuiFormControl from "@material-ui/core/FormControl";

const FormControl = ({ children, ...rest }) => {
  return (
    <MuiFormControl fullWidth margin="dense" {...rest}>
      {children}
    </MuiFormControl>
  );
};

export default FormControl;
