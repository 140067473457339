import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withStyles } from "@material-ui/styles";

const HeaderSummary = props => {
  const {
    arrival_datetime,
    crew_member,
    departure_datetime,
    fishing_events,
    vessel_license_number
  } = props.values;
  const totalWeight = fishing_events
    .map(fishingEvent => {
      return fishingEvent.catches.reduce((totalWeight, fishingCatch) => {
        return totalWeight + parseFloat(fishingCatch.total_weight_lbs);
      }, 0);
    })
    .reduce((eventTotalWeight, catchTotalWeight) => {
      return eventTotalWeight + catchTotalWeight;
    }, 0);
  return (
    <div>
      <p>
        {departure_datetime && (
          <span>
            {moment(departure_datetime).format("MMMM DD, YYYY")}&#65293;
          </span>
        )}
        {arrival_datetime && moment(arrival_datetime).format("MMMM DD, YYYY")}
        {vessel_license_number && (
          <span> &middot; Vessel {vessel_license_number} </span>
        )}
        {crew_member.length && (
          <span>
            {" "}
            &middot; {crew_member.length}{" "}
            {crew_member.length > 1 ? (
              <span>Crew Members</span>
            ) : (
              <span>Crew Member</span>
            )}{" "}
          </span>
        )}
        {fishing_events.length > 0 && (
          <span> &middot; {totalWeight} Total lbs</span>
        )}
      </p>
    </div>
  );
};

HeaderSummary.defaultProps = {
  values: {
    arrival_datetime: new Date().toISOString(),
    crew_member: [],
    departure_datetime: new Date().toISOString(),
    departure_location: "",
    fishing_events: [],
    vessel_license_number: ""
  }
};

export default HeaderSummary;
