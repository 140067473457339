import React from "react";
import { withStyles } from "@material-ui/styles";
import MuiGrid from "@material-ui/core/Grid";
import cn from "classnames";

const styles = theme => ({});

const GridContainer = ({ classes, ...rest }) => {
  return <MuiGrid container {...rest} />;
};

export default withStyles(styles)(GridContainer);
