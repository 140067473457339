import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/styles";
import { withFormik } from "formik";
import { Link } from "@reach/router";
import * as Yup from "yup";
import { westpac } from "../../services";

import {
  Button,
  Divider,
  FormControl,
  Input,
  InputLabel,
  Typography,
  TextField
} from "../../components";
import { updateAccount } from "../../actions/account";

const styles = theme => ({
  resetPassword: {
    padding: `${theme.spacing(1.5)}px 0 ${theme.spacing(1)}px`
  },
  saveButtonContainer: {
    marginTop: theme.spacing(3)
  },
  saveButton: {
    display: "block",
    margin: "0 auto"
  }
});
const CustomDivider = withStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2)
  }
}))(({ classes }) => <Divider className={classes.divider} />);

const AccountForm = ({
  classes,
  values,
  errors,
  handleChange,
  handleSubmit
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="contact_information_address_street"
        label="Address"
        name="contact_information_address_street"
        placeholder="Address 1"
      />
      <TextField
        id="contact_information_address_street_secondary"
        name="contact_information_address_street_secondary"
        placeholder="Address 2"
      />
      <TextField
        label="City"
        id="contact_information_address_city"
        name="contact_information_address_city"
      />
      <TextField
        label="Postal Code"
        id="contact_information_address_postal_code"
        name="contact_information_address_postal_code"
      />
      <TextField
        label="Phone Number"
        name="contact_information_phone_number"
        onChange={handleChange}
      />
      <TextField
        id="contact_information_email_address"
        label="Email Address (Optional)"
        name="contact_information_email_address"
      />
      <CustomDivider />
      <TextField
        id="vessel_license_number"
        label="Primary Vessel Number"
        name="vessel_license_number"
        readOnly
      />
      <CustomDivider />
      <FormControl fullWidth={false}>
        <Typography variant="body2">Reset Password</Typography>
        <Button variant="outlined" color="primary">
          <Link to="/reset-password">Reset Passsword</Link>
        </Button>
      </FormControl>
      <CustomDivider />
      <div className={classes.saveButtonContainer}>
        <Button
          className={classes.saveButton}
          type="submit"
          variant="contained"
          color="primary"
          wide
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
};

const StyledAccountForm = withStyles(styles)(AccountForm);

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    contact_information_address_street: Yup.string().required(
      "Please enter an Address."
    ),
    contact_information_address_street_secondary: Yup.string(),
    contact_information_address_city: Yup.string().required(
      "Please enter a City."
    ),
    contact_information_address_postal_code: Yup.string().required(
      "Please enter a Postal Code."
    ),
    contact_information_phone_number: Yup.string().required(
      "Please enter a Phone Number."
    ),
    contact_information_email_address: Yup.string().email()
  }),
  handleSubmit: (values, { props: { id, updateAccount } }) => {
    const { vessel_license_number, ...filteredValues } = values;
    westpac
      .put(`/user`, filteredValues)
      .then(response => {
        if (response.status === 200) {
          updateAccount(values);
          if (window) {
            window.alert("Your account information has been updated");
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
})(StyledAccountForm);

export default connect(
  state => {
    const account = state.account;
    return {
      contact_information_address_street:
        account.contact_information_address_street,
      contact_information_address_street_secondary:
        account.contact_information_address_street_secondary,
      contact_information_address_city:
        account.contact_information_address_city,
      contact_information_address_postal_code:
        account.contact_information_address_postal_code,
      contact_information_phone_number:
        account.contact_information_phone_number,
      contact_information_email_address:
        account.contact_information_email_address,
      vessel_license_number: account.vessel_license_number
    };
  },
  dispatch => bindActionCreators({ updateAccount }, dispatch)
)(formikEnhancer);
