import React from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Button } from "../index";

import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default class FishedAreasPreviewMap extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.fishingEventIndex === this.props.fishingEventIndex) {
      return false;
    }
  }

  render() {
    const { defaultMapCenter, mapPinCoordinates, setFieldValue } = this.props;

    return (
      <div>
        <Map
          center={defaultMapCenter}
          zoom={this.props.zoom}
          worldCopyJump={true}
          onClick={() => {
            this.props.setPageOffset(8);
            this.props.setSelectedFishingEventAreaIndex(
              this.props.fishingEventIndex
            );
          }}
          style={{
            cursor: "pointer"
          }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={mapPinCoordinates}
            style={{
              zIndex: "998"
            }}
          ></Marker>
        </Map>
        <Button
          style={{
            position: "absolute",
            margin: "auto",
            bottom: "50%",
            top: "50%",
            left: "0",
            right: "0",
            zIndex: "999",
            background: "rgba(255,255,255, 0.9)",
            color: "#000",
            width: "50%",
            height: "50%",
            borderRadius: "0",
            textAlign: "center",
            verticalAlign: "middle"
          }}
          onClick={() => {
            this.props.setPageOffset(8);
            this.props.setSelectedFishingEventAreaIndex(
              this.props.fishingEventIndex
            );
          }}
        >
          <p>
            Click here to select fishing area{" "}
            <span
              style={{
                transform: "rotate(135deg) ",
                display: "inline-block",
                padding: "4px"
              }}
            >
              &harr;
            </span>
          </p>
        </Button>
      </div>
    );
  }
}

FishedAreasPreviewMap.defaultProps = {
  zoom: 5
};
