import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "../../components";

const styles = theme => ({
  root: {
    borderWidth: "1px 1px 0px 1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey["300"],
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(2)}px 0px`
  },
  tableTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1)
  },
  showLess: {
    color: "rgba(35,64,142,1)",
    cursor: "pointer"
  }
});

const SpeciesTable = ({ classes, title, rows }) => {
  const [isVisible, setIsVisible] = useState(true);

  const showHide = () => {
    if (!isVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  if (rows === undefined || rows.length < 1) return null;

  return (
    <div className={classes.root}>
      {isVisible ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Species Name</TableCell>
              <TableCell>Avg MP</TableCell>
              <TableCell>
                <div>Your Avg. Price</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(
              ({ common_name, avg_price_per_lb, market_avg }, index) => {
                const withMarkertPriceRangeFactor = 0.1;
                const atMarketRate =
                  market_avg * (1 - withMarkertPriceRangeFactor) <
                    avg_price_per_lb && avg_price_per_lb < market_avg * 1.1;
                const aboveMarketRate =
                  market_avg * (1 + withMarkertPriceRangeFactor) <
                    avg_price_per_lb && !atMarketRate;
                const belowMarketRate =
                  market_avg * 0.9 > avg_price_per_lb && !atMarketRate;
                let indicatorColor = "#000";
                if (aboveMarketRate) indicatorColor = "#133BAF";
                if (belowMarketRate) indicatorColor = "#9A181F";
                return (
                  <TableRow key={`species-row-${index}`}>
                    <TableCell>{common_name}</TableCell>
                    <TableCell>
                      <span>${market_avg ? market_avg.toFixed(2) : "-"}</span>
                    </TableCell>
                    <TableCell style={{ color: indicatorColor }}>
                      {belowMarketRate && !atMarketRate && (
                        <span>&#x25BC;</span>
                      )}
                      {aboveMarketRate && !atMarketRate && (
                        <span>&#x25B2;</span>
                      )}
                      ${avg_price_per_lb.toFixed(2)}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      ) : null}
    </div>
  );
};

SpeciesTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      common_name: PropTypes.string.isRequired,
      avg_price_per_lb: PropTypes.number.isRequired,
      total_lbs: PropTypes.number.isRequired
    })
  ).isRequired
};

export default withStyles(styles)(SpeciesTable);
