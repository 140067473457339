import React from "react";
import { Formik } from "formik";
/*
Wrapper for Formik, in case we change implementations.
WARNING: components/Input Component uses Formik Context and therefore
must be wrapped by <Formik /> which provides it.
ERRORS will be thrown otherwise.

refer to Formik documentation for prop information.
*/
const Form = props => {
  return <Formik {...props} />;
};

export default Form;
