import { GET_MAP_AREAS } from "../actions/map-areas";

const INITIAL_MAP_AREAS_STATE = {
  mapAreas: [],
  activeMapArea: null
};

export default function mapAreasReducer(
  state = INITIAL_MAP_AREAS_STATE,
  action = {}
) {
  switch (action.type) {
    case GET_MAP_AREAS:
      return action.payload;
    default:
      return state;
  }
}
