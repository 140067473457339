import moment from "moment";

import { QUARTERS, findQuarter } from "./utils";

function findFishingAreas(fishingAreaIds, mapGeoJson) {
  if (!!fishingAreaIds || !!mapGeoJson) return [];
  const geojsonFeatureCollection = mapGeoJson.features;
  return geojsonFeatureCollection.filter(
    feature => fishingAreaIds.indexOf(feature.id) > -1
  );
}

/**
 *
 * @param {Array} approvedReports - An array of all approved reports.
 * @param {Moment} startDate - A moment instance representing the Dashboard's current viewing date.
 * @param {String} period - 'year' | 'month' | 'quarter'
 * @param {Array} marketAveragesBySpeciesList - An array of the average market price averages by species.
 * @param {Array} mapAreas - An array of the map areas geojson.
 * @param {Array} species - An array of fish species.
 *
 *
 */
export default function aggregator(
  approvedReports,
  startDate,
  period,
  marketAveragesBySpeciesList,
  lastYearsCatchTotals,
  mapAreas,
  fishSpecies
) {
  let start, end;

  if (period === "year") {
    start = moment(startDate).startOf("year");
    end = moment(startDate).endOf("year");
  } else if (period === "quarter") {
    const year = moment(startDate).year();
    const month = moment(startDate).month();
    const quarter = findQuarter(month);

    start = moment({
      year,
      month: QUARTERS[quarter][0]
    });
    end = moment({ year, month: QUARTERS[quarter][2] }).endOf("month");
  } else if (period === "month") {
    const year = moment(startDate).year();
    const month = moment(startDate).month();

    start = moment({ year, month });
    end = moment({ year, month }).endOf("month");
  }

  const aggregatedReport = {
    totalFishCaught: 0,
    totalPoundsCaught: 0,
    totalPoundsSold: 0,
    totalSales: 0,
    totalTripCount: 0,
    catchesByMonths: {},
    caughtPoundsByMethodMonthly: [
      {
        label: "Jan",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Feb",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Mar",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Apr",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "May",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "June",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "July",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Aug",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Sept",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Oct",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Nov",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      },
      {
        label: "Dec",
        values: { Bottomfishing: 0, Trolling: 0, Spearfishing: 0 }
      }
    ]
  };
  const species = {};
  const fishedMapAreaIds = {};

  let mapAreasObject = {};
  mapAreas.forEach(area => {
    mapAreasObject[area.id] = area;
  });

  approvedReports.forEach(report => {
    const arrivalDatetime = moment(report.fishing_trip.arrival_datetime);
    const isSelectedPeriodReport = arrivalDatetime.isBetween(
      start,
      end,
      null,
      "[]"
    );
    const isAnnualReport = arrivalDatetime.isBetween(
      moment(startDate).startOf("year"),
      moment(startDate).endOf("year"),
      null,
      "[]"
    );

    if (isAnnualReport) {
      // Aggregate information by fishing events
      report.fishing_trip.fishing_events.forEach(fishingEvent => {
        const { catches } = fishingEvent;
        const parsedFishedAreas = JSON.parse(fishingEvent.fished_areas_ids);
        if (!!parsedFishedAreas.length && !!parsedFishedAreas[0].length) {
          if (fishedMapAreaIds.hasOwnProperty(report.map_areas_id)) {
            fishedMapAreaIds[report.map_areas_id] = [
              ...fishedMapAreaIds[report.map_areas_id],
              ...parsedFishedAreas
            ];
          } else {
            fishedMapAreaIds[report.map_areas_id] = parsedFishedAreas;
          }
        }

        // Aggregate information by fishing events catches
        catches.forEach(c => {
          // Do not include empty catches
          if(!c.species_id) {
            return;
          }

          const totalLbs = c.total_weight_lbs;
          const totalCount = c.total_count;
          const speciesEntry = fishSpecies.find(
            entry => entry.id === c.species_id
          );
          const catchMonthIndex = new Date(
            report.fishing_trip.arrival_datetime
          ).getMonth();

          // Prevent NaN if method used isn't defined in aggregatedReport
          const monthlyMethodCatchWeight = aggregatedReport.caughtPoundsByMethodMonthly[catchMonthIndex].values[
            speciesEntry.caught_by
          ] 
          aggregatedReport.caughtPoundsByMethodMonthly[catchMonthIndex].values[
            speciesEntry.caught_by
          ] = monthlyMethodCatchWeight ? monthlyMethodCatchWeight + totalLbs : totalLbs;

          if (species.hasOwnProperty(c.species_id)) {
            species[c.species_id].total_catch_event_weight_lbs += totalLbs;
            species[c.species_id].total_count += totalCount;
          } else {
            species[c.species_id] = {
              total_catch_event_weight_lbs: totalLbs,
              total_count: totalCount,
              total_sales: 0,
              total_weight_lbs: 0
            };
          }
        });
      });

      // Aggregate information by sales order items
      report.fishing_trip.sales_order.forEach(({ items }) => {
        items.forEach(item => {
          const totalSales = item.total_weight_lbs * item.price_per_lb_usd;
          const totalLbs = item.total_weight_lbs;
          if (species.hasOwnProperty(item.species_id)) {
            species[item.species_id].total_sales += totalSales;
            species[item.species_id].total_weight_lbs += totalLbs;
          } else {
            species[item.species_id] = {
              total_catch_event_weight_lbs: 0,
              total_count: 0,
              total_sales: totalSales,
              total_weight_lbs: totalLbs
            };
          }
          aggregatedReport.totalPoundsSold += item.total_weight_lbs;
        });
      });
    }

    if (isSelectedPeriodReport) {
      aggregatedReport.totalTripCount++;

      report.fishing_trip.fishing_events.forEach(fishingEvent => {
        const { catches } = fishingEvent;
        // Aggregate information by fishing events catches
        catches.forEach(c => {
          aggregatedReport.totalPoundsCaught += c.total_weight_lbs;
        });

        // Aggregate information by sales order items
        report.fishing_trip.sales_order.forEach(({ items }) => {
          items.forEach(item => {
            const totalSales = item.total_weight_lbs * item.price_per_lb_usd;
            aggregatedReport.totalSales += totalSales;
          });
        });
      });
    }
  });

  const entries = Object.entries(species);
  const metricsBySpeciesOfFish = [];
  const marketAveragesBySpecies = {};

  marketAveragesBySpeciesList.forEach(marketMetric => {
    if (marketMetric.species) {
      marketAveragesBySpecies[marketMetric.species.id] = {
        month_average_market_price_usd:
          marketMetric.month_average_market_price_usd,
        year_average_market_price_usd:
          marketMetric.year_average_market_price_usd
      };
    }
  });

  for (const [id, species] of entries) {
    metricsBySpeciesOfFish.push({
      id,
      avg_price_per_lb: species.total_sales
        ? species.total_sales / species.total_weight_lbs
        : 0,
      total_lbs: species.total_weight_lbs,
      market_avg:
        marketAveragesBySpecies[id] &&
        marketAveragesBySpecies[id].year_average_market_price_usd
          ? marketAveragesBySpecies[id].year_average_market_price_usd
          : 0,
      total_catch_event_weight_lbs: species.total_catch_event_weight_lbs
        ? species.total_catch_event_weight_lbs
        : 0
    });
  }

  return {
    ...aggregatedReport,
    metricsBySpeciesOfFish,
    marketAveragesBySpecies,
    lastYearsCatchTotals,
    fishedMapAreaIds,
    marketAveragesBySpecies
  };
}
