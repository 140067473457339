import React from "react";
import cn from "classnames";
import { Field } from "formik";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import { FormControl } from "../";

const FormikSelect = withStyles(theme => ({
  select: {
    display: "block",
    backgroundColor: theme.palette.common.white,
    height: "1.875rem",
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: "6px",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    appearance: "none",
    margin: 0,
    marginTop: "16px"
  }
}))(
  ({
    classes,
    field: { name, value, onChange, onBlue },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    children,
    ...rest
  }) => {
    const selectClasses = cn({
      [classes.select]: true,
      [className]: className !== undefined
    });
    return (
      <select
        name={name}
        id={id}
        value={value}
        id={id}
        m
        className={selectClasses}
        {...rest}
      >
        {children}
      </select>
    );
  }
);

const Select = ({ multiple, onChange, ...rest }) => {
  const handleChange = e => {
    let values;

    if (multiple) {
      const options = e.target.options;
      values = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          values.push(options[i].value);
        }
      }
    } else {
      values = e.target.value;
    }

    onChange(e.target.id, values);
  };

  return (
    <Field
      multiple={multiple}
      onChange={handleChange}
      component={FormikSelect}
      {...rest}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Select.defaultProps = {
  multiple: false
};

export default Select;
