import moment from "moment";

import { QUARTERS, findQuarter } from "./utils";
/**
 *
 * @param {Array} approvedReports - An array of all approved reports.
 * @param {Moment} startDate - A moment instance representing the Dashboard's current viewing date.
 * @param {String} period - 'year' | 'month' | 'quarter'
 */
export function forward(approvedReports, startDate, period) {
  let endOfCurrentPeriod;
  if (period === "year") {
    endOfCurrentPeriod = moment(startDate).endOf("year");
  } else if (period === "quarter") {
    const quarter = findQuarter(startDate.month());
    const endOfQuarter = QUARTERS[quarter][2];
    endOfCurrentPeriod = moment({
      year: startDate.year(),
      month: endOfQuarter
    }).endOf("month");
  } else if (period === "month") {
    endOfCurrentPeriod = moment(startDate).endOf("month");
  }

  return approvedReports.some(report => {
    return moment(report.fishing_trip.arrival_datetime).isAfter(
      endOfCurrentPeriod
    );
  });
}

/**
 *
 * @param {Array} approvedReports - An array of all approved reports.
 * @param {Moment} startDate - A moment instance representing the Dashboard's current viewing date.
 * @param {String} period - 'year' | 'month' | 'quarter'
 */
export function backward(approvedReports, startDate, period) {
  let beginningOfCurrentPeriod;

  if (period === "year") {
    beginningOfCurrentPeriod = moment(startDate).startOf("year");
  } else if (period === "quarter") {
    const quarter = findQuarter(startDate.month());
    const beginningOfQuarter = QUARTERS[quarter][0];
    beginningOfCurrentPeriod = moment({
      year: startDate.year(),
      month: beginningOfQuarter
    }).startOf("month");
  } else if (period === "month") {
    beginningOfCurrentPeriod = moment(startDate).startOf("month");
  }

  return approvedReports.some(report => {
    return moment(report.fishing_trip.arrival_datetime).isBefore(
      beginningOfCurrentPeriod
    );
  });
}

export default { forward, backward };
