import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Router } from "@reach/router";
import { withStyles } from "@material-ui/styles";

import Account from "../views/Account/Account";
import Dashboard from "../views/Dashboard/Dashboard";
import Reports from "../views/Reports/Reports";
import NavTabs from "../views/NavTabs/NavTabs";

import { getAccountInfo } from "../actions/account";
import { getReports } from "../actions/reports";
import { getSpecies } from "../actions/species";
import { getFishingMethods } from "../actions/fishing-methods";
import { getVendorLicenses } from "../actions/vendors";
import { getPorts } from "../actions/ports";
import { getMapAreas } from "../actions/map-areas";
import { loadApplicationSettings } from "../actions/settings";
import {
  getMarketMetrics,
  getLastYearsCatchTotals
} from "../actions/market-metrics";
import { getLeaderboardRank } from "../actions/market-metrics";
import { getFields } from "../actions/fields";

import { ProtectedRoute } from "../components";

const headerHeight = 73;

const styles = theme => ({
  wrapper: {
    maxWidth: theme.container.maxWidth,
    margin: `0 auto`,
    height: "100vh"
  },
  content: {
    paddingTop: headerHeight,
    height: "100vh"
  }
});

class App extends React.Component {
  async componentDidMount() {
    const {
      getAccountInfo,
      getReports,
      getSpecies,
      getFishingMethods,
      getVendorLicenses,
      getPorts,
      getMapAreas,
      loadApplicationSettings,
      getMarketMetrics,
      getFields,
      getLastYearsCatchTotals,
      getLeaderboardRank
    } = this.props;

    await Promise.all([
      getAccountInfo(),
      //TODO: fix getReports to use westpac service
      getReports(),
      getSpecies(),
      getFishingMethods(),
      getVendorLicenses(),
      getPorts(),
      getMapAreas(),
      loadApplicationSettings(),
      getMarketMetrics(),
      getFields(),
      getLastYearsCatchTotals(),
      getLeaderboardRank()
    ]);
  }

  render() {
    const { classes, location } = this.props;

    return (
      <div>
        <NavTabs pathname={location.pathname} />
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Router primary={false}>
              <ProtectedRoute Component={Account} path="account" />
              <ProtectedRoute Component={Dashboard} path="dashboard" />
              <ProtectedRoute Component={Reports} path="reports" default />
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReports,
      getSpecies,
      getAccountInfo,
      getFishingMethods,
      getVendorLicenses,
      getPorts,
      getMapAreas,
      loadApplicationSettings,
      getMarketMetrics,
      getFields,
      getLastYearsCatchTotals,
      getLeaderboardRank
    },
    dispatch
  );

const StyledApp = withStyles(styles)(App);

export default connect(null, mapDispatchToProps)(StyledApp);
