import moment from "moment";

export const UPDATE_START_DATE = "DASHBOARD/UPDATE_START_DATE";
export const UPDATE_PERIOD = "DASHBOARD/UPDATE_PERIOD";
export const UPDATE_APPROVED_REPORTS = "DASHBOARD/UPDATE_APPROVED_REPORTS";

export const incrementStartDate = (startDate, period) => {
  let newStartDate;

  if (period === "year") {
    newStartDate = moment(startDate).add(1, "year");
  } else if (period === "quarter") {
    newStartDate = moment(startDate).add(3, "month");
  } else if (period === "month") {
    newStartDate = moment(startDate).add(1, "month");
  }

  return {
    type: UPDATE_START_DATE,
    payload: {
      startDate: newStartDate
    }
  };
};

export const decrementStartDate = (startDate, period) => {
  let newStartDate;

  if (period === "year") {
    newStartDate = moment(startDate).subtract(1, "year");
  } else if (period === "quarter") {
    newStartDate = moment(startDate).subtract(3, "month");
  } else if (period === "month") {
    newStartDate = moment(startDate).subtract(1, "month");
  }

  return {
    type: UPDATE_START_DATE,
    payload: {
      startDate: newStartDate
    }
  };
};

export const updatePeriod = period => ({
  type: UPDATE_PERIOD,
  payload: {
    period
  }
});

export const getApprovedReports = reports => {
  const approvedReports = reports
    .filter(({ is_approved }) => is_approved)
    .map(report => ({ ...report, submitted_at: moment(report.submitted_at) }));

  return {
    type: UPDATE_APPROVED_REPORTS,
    payload: {
      approvedReports
    }
  };
};
