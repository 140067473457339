import React from "react";

const ProgressBar = props => {
  const { width = "100%", progreeBarWidth = "0" } = props;
  return (
    <div
      style={{
        width,
        height: "0.4rem",
        background: "#ccc",
        position: "fixed",
        bottom: "0"
      }}
    >
      <div
        style={{
          width: `${progreeBarWidth}`,
          height: "0.4rem",
          background: "#000",
          transition: "width 0.75s"
        }}
      />
    </div>
  );
};

export default ProgressBar;
