import { westpac } from "../services";

export const GET_VENDOR_LICENSES = "GET_VENDOR_LICENSES";

export const getVendorLicenses = () => {
  return dispatch => {
    return westpac.get("/vendor-licenses").then(({ data }) => {
      dispatch({
        type: GET_VENDOR_LICENSES,
        payload: { vendor_licenses: data.vendor_licenses }
      });
    });
  };
};
