import React from "react";
import MuiTableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  root: {
    "&:nth-child(odd)": {
      backgroundColor: "rgba(233, 235, 243, 0.5)"
    }
  }
});

const TableRow = ({ classes, ...rest }) => {
  return <MuiTableRow classes={classes} {...rest} />;
};

export default withStyles(styles)(TableRow);
