import React from "react";

const PendingReportsProgressBar = props => {
  const { progreeBarWidth = "0%" } = props;
  return (
    <div
      style={{
        width: "100%",
        height: "0.5rem",
        background: "#ccc",
        marginBottom: "0.1rem"
      }}
    >
      <div
        style={{
          width: `${progreeBarWidth}`,
          height: "0.5rem",
          background: "rgba(35, 64, 142, 1)"
        }}
      />
    </div>
  );
};

export default PendingReportsProgressBar;
