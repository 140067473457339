import React from "react";
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";
import PropTypes from "prop-types";

const styles = {
  outlined: {
    fontWeight: 400
  },
  wide: {
    width: "100%",
    maxWidth: "192px"
  }
};

const Button = ({
  children,
  classes,
  className,
  color,
  style,
  variant,
  wide,
  ...rest
}) => {
  const buttonClasses = cn({
    [classes.wide]: wide,
    [className]: className !== undefined
  });
  return (
    <MuiButton
      classes={{ outlined: classes.outliend }}
      className={buttonClasses}
      color={color}
      style={style}
      variant={variant}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["primary"]),
  style: PropTypes.object,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  wide: PropTypes.bool
};

export default withStyles(styles)(Button);
