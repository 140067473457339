import { publicApiService } from "../services";

const CLIENT_ID = process.env.REACT_APP_CLIENT_APP_ID;

export const SET_FIELDS = "SET_FIELDS";

export const getFields = () => {
  return dispatch => {
    return publicApiService
      .get("/fields", {
        params: {
          client_id: CLIENT_ID
        }
      })
      .then(({ data }) => {
        const fields = data.map(field => [field.field, field.body]);

        dispatch({ type: SET_FIELDS, fields });
      });
  };
};
