import React from "react";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";

const styles = theme => ({
  container: {
    maxWidth: theme.container.maxWidth,
    margin: `${theme.spacing(3)}px auto`
  }
});

const Container = ({ classes, className, ...rest }) => {
  const containerClasses = cn({
    [classes.container]: true,
    [className]: className !== undefined
  });
  return <div className={containerClasses} {...rest} />;
};

export default withStyles(styles)(Container);
