import React from "react";
import { GeoJSON } from "react-leaflet";

export default class FishedAreasMap extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <GeoJSON
          data={this.props.areas}
          color="#ccc"
          fill="#ccc"
          weight={1}
          onEachFeature={this.props.onEachFeature}
        />
      </div>
    );
  }
}
