import {
  GET_MARKET_METRICS,
  GET_LAST_YEARS_CATCH_TOTALS,
  GET_LEADERBOARD_RANK
} from "../actions/market-metrics";

const INITIAL_METRICS_STATE = {
  average_market_prices: [
    {
      species: null,
      year_average_market_price_usd: null,
      month_average_market_price_usd: null
    }
  ],
  last_years_catch_totals: null,
  leaderboard_rank: null
};

export default function metricsReducer(
  state = INITIAL_METRICS_STATE,
  action = {}
) {
  switch (action.type) {
    case GET_MARKET_METRICS:
      return { ...state, ...action.payload.data };
    case GET_LAST_YEARS_CATCH_TOTALS:
      return { ...state, ...{ last_years_catch_totals: action.totals } };
    case GET_LEADERBOARD_RANK:
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
}
