import React from "react";
import { Map, TileLayer } from "react-leaflet";
import { GeoJSON } from "react-leaflet";
import { westpac } from "../../services";
import axios from "axios";

function findFishingAreas(fishingAreaIds, mapGeojson) {
  return mapGeojson.features.filter(
    feature => fishingAreaIds.indexOf(feature.id) > -1
  );
}

export default class DashboardMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapAreas: [],
      totalAreas: 0,
      mapAreaOpacityById: {}
    };
  }

  async componentDidMount() {
    try {
      const {
        data: { map_areas }
      } = await westpac.get("/map-areas");
      const relevantMapIds = Object.keys(this.props.fishedMapAreaIds);
      relevantMapIds.forEach(async mapId => {
        const mapArea = map_areas.find(area => {
          return area.id == mapId;
        });
        const { data } = await axios.get(mapArea.file_url);
        const geojsonAreasToRender = findFishingAreas(
          this.props.fishedMapAreaIds[mapId],
          data
        );
        this.setState({
          mapAreas: [...this.state.mapAreas, ...geojsonAreasToRender]
        });
      });
    } catch (err) {
      console.error("Error: ", err);
    }
  }

  render() {
    const { mapAreas } = this.state;
    const { fishedMapAreaIds } = this.props;
    let totalFeatureAreaCount = 0;
    let countByFeatureId = {};

    Object.keys(fishedMapAreaIds).forEach(mapVersion => {
      fishedMapAreaIds[mapVersion].forEach(areaId => {
        totalFeatureAreaCount++;
        if (countByFeatureId.hasOwnProperty(areaId)) {
          countByFeatureId[areaId]++;
        } else {
          countByFeatureId[areaId] = 1;
        }
      });
    });

    const opacityRatio = 1 / totalFeatureAreaCount;

    if (mapAreas.length === 0) return false;
    return (
      <div>
        <Map center={this.props.defaultMapCenter} zoom={5} worldCopyJump={true}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          <GeoJSON
            key={`dashboard-geojson-${mapAreas.length}`}
            data={mapAreas}
            weight={1}
            color="#1B2E7B"
            style={feature => {
              const featureOpacity =
                opacityRatio * countByFeatureId[feature.id];
              let fillColor = "#000";
              if (featureOpacity <= 1) fillColor = "#2954B6";
              if (featureOpacity < 0.8) fillColor = "#587AC6";
              if (featureOpacity < 0.6) fillColor = "#88A0D7";
              if (featureOpacity < 0.4) fillColor = "#B8C6E7";
              if (featureOpacity < 0.2) fillColor = "#E8EDF8";

              return {
                fillColor,
                fillOpacity: "0.9"
              };
            }}
          />
        </Map>
        <div
          style={{
            fontSize: "0.75rem",
            height: "12px",
            maxWidth: "300px",
            minWidth: "200px",
            padding: "8px 0",
            width: "50%"
          }}
        >
          <span style={{ fontSize: "0.75rem", whiteSpace: "nowrap" }}>
            Percent of Total Catch Activity
          </span>
          <div
            style={{
              height: "12px",
              background:
                "linear-gradient(90deg, rgba(23,82,188,0.1) 0%, #1752BC 100%)"
            }}
          />
          <span style={{ fontSize: "0.75rem" }}>0%</span>
          <span style={{ fontSize: "0.75rem", float: "right" }}>100%</span>
        </div>
      </div>
    );
  }
}
