import { westpac } from "../services";

export const SET_APPLICATION_SETTINGS = "SET_APPLICATION_SETTINGS";

export const fetchApplicationSettings = async () => {
  const response = await westpac.get("/settings");

  return JSON.parse(response.data);
};

export const storeApplicationSettings = async settings => {
  window.localStorage.setItem("application_settings", JSON.stringify(settings));
};

export const retrieveApplicationSettings = async () => {
  try {
    console.info("Updating application settings");
    return await fetchApplicationSettings();
  } catch {
    console.info("Using stored application settings");
    const localSettings = window.localStorage.getItem("application_settings");
    if (localSettings) {
      return JSON.parse(window.localStorage.getItem("application_settings"));
    }
  }
};

export const loadApplicationSettings = () => {
  return async dispatch => {
    let settings = await retrieveApplicationSettings();

    if (settings) {
      dispatch({
        type: SET_APPLICATION_SETTINGS,
        /* There's a weird redux store issue that requires the settings
         * object to be cloned for the initial map render.
         * The parse functions above shouldn't be returning the same object?
         */
        settings
      });

      storeApplicationSettings(settings);
    }
  };
};
