import moment from "moment";

import {
  UPDATE_START_DATE,
  UPDATE_PERIOD,
  UPDATE_APPROVED_REPORTS
} from "../actions/dashboard";

const INITIAL_STATE = {
  startDate: moment(),
  period: "year",
  approvedReports: []
};

export default function dashboardReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_START_DATE:
      return { ...state, startDate: action.payload.startDate };
    case UPDATE_PERIOD:
      return { ...state, period: action.payload.period };
    case UPDATE_APPROVED_REPORTS:
      return { ...state, approvedReports: action.payload.approvedReports };
    default:
      return state;
  }
}
