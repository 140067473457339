import React from "react";
import Select from "react-select";

const customStyles = {
  menu: styles => {
    return {
      ...styles,
      zIndex: "11000"
    };
  }
};

const SelectSearch = ({
  field,
  form,
  options,
  isClearable,
  isMulti = false
}) => {
  const onChange = option => {
    if (!option) {
      return form.setFieldValue(field.name, null);
    }
    if (isMulti && !option) {
      return form.setFieldValue(field.name, []);
    } else {
      return form.setFieldValue(
        field.name,
        isMulti ? option.map(item => item.value) : option.value
      );
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            option =>
              field.value != null && field.value.indexOf(option.value) >= 0
          )
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      isClearable={isClearable}
      styles={customStyles}
    />
  );
};

export default SelectSearch;
