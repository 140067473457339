import { GET_FISHING_METHODS } from "../actions/fishing-methods";
const INITIAL_STATE = [];

export default function fishingMethodsReducer(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case GET_FISHING_METHODS:
      return action.payload.fishingMethods;
    default:
      return state;
  }
}
