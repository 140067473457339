import { combineReducers } from "redux";

import accountReducer from "./account";
import dashboardReducer from "./dashboard";
import fishingReportsReducer from "./fishing-reports";
import speciesReducer from "./species";
import userReducer from "./user";
import fishingMethodsReducer from "./fishing-methods";
import vendorsReducer from "./vendors";
import portsReducer from "./ports";
import settingsReducer from "./settings";
import metricsReducer from "./market-metrics";
import fieldsReducer from "./fields";

import { LOGOUT_USER } from "../actions/auth";
import mapAreasReducer from "./map-areas";

const appReducer = combineReducers({
  account: accountReducer,
  dashboard: dashboardReducer,
  fishingReports: fishingReportsReducer,
  species: speciesReducer,
  user: userReducer,
  fishingMethods: fishingMethodsReducer,
  vendors: vendorsReducer,
  ports: portsReducer,
  mapAreas: mapAreasReducer,
  settings: settingsReducer,
  marketMetrics: metricsReducer,
  fields: fieldsReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
