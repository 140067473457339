import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { bindActionCreators } from "redux";
import { navigate } from "@reach/router";

import {
  initialFishingEvent,
  initialSaleState,
  initialState,
  initialFishingEventCatches,
  initialLostFishState
} from "../../reducers/fishing-reports";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  GridContainer,
  GridItem,
  ImageUpload,
  FishedAreasMap,
  FishedAreasPreviewMap,
  Form,
  FormControl,
  HeaderSummary,
  Input,
  InputLabel,
  PendingReportsProgressBar,
  PopupModal,
  ProgressBar,
  Typography,
  Select,
  SelectSearch,
  Tooltip
} from "../../components";
import { PeriodTabs, Tab } from "../Dashboard/PeriodTabs";
import Checkbox from "@material-ui/core/Checkbox";
import { Divider } from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InfoIcon from "@material-ui/icons/Info";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Field, FieldArray } from "formik";
import moment from "moment";

import {
  addReport,
  updateReport,
  deleteReport,
  getReports,
  markFishingReportDone
} from "../../actions/reports";

import {
  SchemaValidation,
  formatFloat,
  formProgressStepUtil,
  formProgressWidthUtil,
  groupedFishingDetailsFromEvents,
  speciesListFromEvent,
  totalPoundsFromEvents,
  totalPoundsFromEvent,
  totalsFromSales,
  TROLLING_METHOD_ID,
  BOTTOMFISHING_METHOD_ID,
  SPEARFISHING_METHOD_ID,
  MAX_FISHING_CATCH_UPLOADS
} from "./utils";

const styles = theme => ({
  buttonOutline: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: "#fff",
    color: theme.palette.primary.main,
    padding: "0 0.5rem",
    width: "fit-content"
  },
  buttonModalOptions: {
    float: "right",
    fontSize: "1rem",
    color: `${theme.palette.primary.main}`
  },
  backButton: {
    background: "transparent",
    color: theme.palette.primary.main,
    fontWeight: "700",
    left: "0",
    position: "absolute",
    top: "1.8rem"
  },
  bottomButtonBar: {
    position: "fixed",
    bottom: "0",
    left: "0",
    padding: "1rem",
    background: "#fff",
    boxShadow: "0 -4px 6px 0px rgba(0,0,0,0.2)",
    zIndex: "10000"
  },
  box: {
    border: "1px solid #ccc",
    margin: `${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(0.5)}px`
  },
  container: {
    margin: 0,
    paddingTop: "1rem"
  },
  card: {
    width: "100%",
    margin: `${theme.spacing(1)}px`,
    minHeight: "88vh",
    position: "relative"
  },
  cardBody: {
    paddingBottom: theme.spacing(7)
  },
  countSpan: {
    color: "#fff",
    background: theme.palette.primary.main,
    borderRadius: "3px",
    marginLeft: "0.8rem",
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    verticalAlign: "middle"
  },
  crewWrapper: {
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    marginBottom: "6px",
    padding: "0.2rem 0.4rem"
  },
  crewCountContainer: {
    float: "right"
  },
  crewCountSpan: {
    color: "#fff",
    background: theme.palette.primary.main,
    borderRadius: "3px",
    marginLeft: "0.4rem",
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`
  },
  divider: {
    margin: "1rem 0 1.5rem"
  },
  dividerSmallPadding: {
    margin: "0.5rem 0"
  },
  editButton: {
    color: theme.palette.primary.main,
    fontWeight: "700",
    position: "absolute",
    right: "6px",
    top: "6px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  errorSpan: {
    color: "#ed7637",
    fontWeight: "700",
    fontSize: "1rem",
    float: "right"
  },
  eventOptions: {
    position: "absolute",
    top: "0.5rem",
    right: "0",
    padding: "0.2rem 0.6rem 0.2rem 0.6rem",
    background: "#fff"
  },
  eventOptionItems: {
    margin: "-1px 0 0 0",
    padding: "0.2rem 0.6rem 0.2rem 0.6rem",
    border: "1px solid #e0e0e0",
    "&:hover": {
      cursor: "pointer"
    }
  },
  fieldSpacingWrapper: {
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    padding: "0.5rem",
    marginBottom: "0.2rem"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    width: "100%"
  },
  hidden: {
    display: "none"
  },
  hiddenEvent: {
    display: "initial"
  },
  inputLabelWithToolTipWrapper: {
    display: "flex",
    alignItems: "flex-end",
    "&> label": {
      marginRight: "3px"
    }
  },
  mapContainer: {
    height: "180px"
  },
  name: {
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: "500"
  },
  pendingCardHeader: {
    color: theme.palette.primary.main,
    display: "flex",
    fontSize: "1rem",
    justifyContent: "space-between"
  },
  pendingCardStep: {
    alignSelf: "center",
    color: theme.palette.primary.main,
    float: "right",
    fontSize: "1rem"
  },
  pendingCardBody: {
    textTransform: "capitalize"
  },
  primaryTitle: {
    fontWeight: "900",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1)
  },
  primaryCard: {
    fontWeight: "400",
    fontSize: "1.375rem",
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    textAlign: "left",
    "&:hover": {
      cursor: "pointer"
    }
  },
  primaryHeader: {
    padding: "0"
  },
  proceedButton: {
    float: "right",
    background: theme.palette.primary.main,
    color: "#fff",
    padding: "6px"
  },
  radioGroup: {
    paddingTop: "0"
  },
  removeCrewMemberBtn: {
    alignSelf: "flex-end",
    color: theme.palette.primary.main,
    fontWeight: "600"
  },
  reportBox: {
    border: "1px solid #ccc",
    margin: `-1px 0 0 0`,
    padding: `${theme.spacing(0.5)}px`,
    "&:hover": {
      cursor: "pointer"
    }
  },
  reviewItem: {
    marginBottom: "0.5rem"
  },
  selectAreasButton: {
    position: "absolute",
    bottom: "0",
    right: "0",
    background: "rgba(35,64,142,1)",
    color: "#FFFFFF",
    zIndex: "10000",
    fontWeight: "700"
  },
  skipSales: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer"
    }
  },
  smallHeader: {
    fontSize: "1rem",
    marginBottom: "0.5rem"
  },
  submitBtn: {
    width: "100%",
    maxWidth: "192px",
    margin: "auto",
    background: theme.palette.primary.main,
    color: "#fff",
    marginBottom: "6px"
  },
  styledDetail: {
    borderRadius: "4px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
    margin: "0 0 0.5rem 0",
    "& summary": {
      cursor: "pointer",
      listStyle: "none",
    },
    "& summary::-webkit-details-marker": {
      display: "none"
    }
  },
  visible: {
    display: "initial",
    width: "100%",
    paddingBottom: "2rem"
  },
  visibleEvent: {
    display: "initial"
  },
  vesselLicenseRight: {
    float: "right",
    fontSize: "1rem",
    fontWeight: "500"
  },
  resubmitButton: {
    display: "inline-block",
    margin: "0 auto",
    padding: "0 20px",
    fontSize: "12px",
    background: "#23408E",
    color: "white",
    float: "left"
  },
  markAsDoneButton: {
    display: "inline-block",
    margin: "0 auto",
    padding: "0",
    fontSize: "12px",
    background: "white",
    color: "#23408E",
    float: "right"
  },
  rejectedButtonText: {
    fontSize: "12px",
    fontWeight: "bold"
  },
  reasonHeader: {
    color: "#AC2828",
    paddingBottom: "0",
    fontSize: "12px"
  },
  reportLabel: {
    fontSize: "0.9rem"
  },
  reportText: {
    padding: "0 0 12px",
    fontSize: "12px"
  },
  reportCard: {
    marginBottom: "10px"
  }
});

const UNKNOWN_SPECIES = "Unknown";
const REEF_FISH_METHOD = "Reef-fishing";
const PREDATOR_SPECIES_CATEGORY = "Predator";

const Reports = props => {
  const {
    account,
    classes,
    addReport,
    deleteReport,
    updateReport,
    markFishingReportDone,
    fishingReports,
    fishingMethods,
    species,
    vendors,
    ports,
    mapAreas,
    settings
  } = props;

  const [initialFormState, setInitialFormState] = useState({
    ...initialState,
    vessel_license_number: account.vessel_license_number,
    account_id: account.id,
    crew_member: [
      {
        first_name: account.first_name,
        last_name: account.last_name,
        business_license_number: account.business_license_number
      }
    ],
    verified: false
  });
  const pinCoordinates = [
    settings.default_map_options.latitude,
    settings.default_map_options.longitude
  ];
  const [savedFormValues, setSavedFormValues] = useState({
    ...initialFormState
  });

  const [currentReportId, setCurrentReportId] = useState(null);
  const [currentReportMapArea, setCurrentReportMapArea] = useState(null);
  const [editingEventIndex, setEditingEventIndex] = useState(-1);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [isNewReport, setIsNewReport] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);
  const [reportError, setReportError] = useState("");
  const [progreeBarWidth, setProgreeBarWidth] = useState(0);
  const [defaultMapCenter, setDefaultMapCenter] = useState(pinCoordinates);
  const [mapPinCoordinates, setMapPinCoordinates] = useState(pinCoordinates);
  // Update the pin coordinates if the settings object changes
  useEffect(() => {
    const pinCoordinates = [
      settings.default_map_options.latitude,
      settings.default_map_options.longitude
    ];
    setDefaultMapCenter(pinCoordinates);
    setMapPinCoordinates(pinCoordinates);
  }, [props.settings]);
  const [
    selectedFishingEventAreaIndex,
    setSelectedFishingEventAreaIndex
  ] = useState(0);
  const [draftFishingAreaIds, setDraftFishingAreaIds] = useState([]);
  const [draftFishingMethodId, setDraftFishingMethodId] = useState("");

  const [
    salesInformationSelectableFishIds,
    setSalesInformationSelectableFishIds
  ] = useState([]);
  const [showSubmittedReports, setShowSubmittedReports] = useState(false);
  const [selectedReportsTab, setSetSelectedReportsTab] = useState("rejected");

  const [activeMapAreaId, setActiveMapAreaId] = useState(null);
  useEffect(() => {
    const mapAreaId = mapAreas.activeMapArea ? mapAreas.activeMapArea.id : null;
    setActiveMapAreaId(mapAreaId);
  }, [mapAreas, activeMapAreaId]);

  useEffect(() => {
    let mapArea = null;
    if (currentReportId) {
      const currFishingReport = fishingReports.find(
        report => report.id === currentReportId
      );
      mapArea = mapAreas.mapAreas.find(
        mapArea => mapArea.id === currFishingReport.map_areas_id
      );
    }
    setCurrentReportMapArea(mapArea);
  }, [mapAreas, fishingReports, currentReportId, currentReportMapArea]);

  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(function (position) {
      setMapPinCoordinates([
        position.coords.latitude,
        position.coords.longitude
      ]);
    });
  }

  function scrollToTop() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }

  function saveReport({ newReport, originalReport, revisionReport, id }) {
    if (!newReport) {
      return updateReport({
        originalReport,
        revisionReport,
        id
      }).then(data => {
        return data;
      });
    }
    return addReport(originalReport).then(data => {
      return data;
    });
  }

  function findFishingMethod(fishingMethodId) {
    const matchingMethod = fishingMethods.find(
      method => method.id == fishingMethodId
    );
    return matchingMethod ? matchingMethod.type : "";
  }

  function markAsDone(reportId) {
    markFishingReportDone(reportId);
  }

  function renderFilteredFishingReports(fishingReports) {
    let filteredReports = fishingReports.filter((report, i) => {
      if (selectedReportsTab === "rejected") {
        return report.is_approved === false;
      } else if (selectedReportsTab === "approved") {
        return report.is_approved === true;
      } else if (selectedReportsTab === "pending") {
        return report.is_approved === null && report.submitted_at;
      }
    });

    // Move the reports marked done and rejected to the bottom of the list
    if (selectedReportsTab === "rejected") {
      filteredReports = filteredReports.sort((a, b) => a.done - b.done);
    }

    let REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP = null;
    if (props.fields.length) {
      let field = props.fields.find(([field, _description]) => {
        return field === "REMEDY_REJECTED_FISHING_REPORT_TOOLTIP";
      });
      if (field) {
        REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP = field[1];
      }
    }

    return (
      <>
        {selectedReportsTab === "rejected" && (
          <div>
            <span className={classes.reportText}>
              {REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP}
            </span>
            <Divider
              style={{
                margin: "8px 0"
              }}
            />
          </div>
        )}

        {filteredReports.map((report, i) => {
          const { fishing_trip, id, submitted_at, reason, done } = report;
          const { departure_datetime, arrival_datetime } = fishing_trip;
          const {
            vessel_license_number,
            crew_member,
            fishing_events
          } = fishing_trip;
          const totalFishingLbs = totalPoundsFromEvents(fishing_events);

          return (
            <Card className={classes.reportCard}>
              <CardBody
                style={{
                  padding: "8px 0"
                }}
              >
                <p
                  style={{
                    margin: "0"
                  }}
                >
                  Report #{id} - Submitted{" "}
                  {moment(submitted_at).format("MMMM DD, YYYY")}
                </p>
              </CardBody>
              <CardBody className={classes.reportText}>
                {moment(departure_datetime).format("MMMM DD, YYYY")} -{" "}
                {moment(arrival_datetime).format("MMMM DD, YYYY")} &middot;
                Vessel {vessel_license_number} &middot;{" "}
                {crew_member && crew_member.length} Crew member(s) &middot;{" "}
                {totalFishingLbs} Total Pounds
              </CardBody>
              {selectedReportsTab === "rejected" ? (
                <Fragment>
                  <Divider
                    style={{
                      margin: "0 8px"
                    }}
                  />
                  <CardBody className={classes.reasonHeader}>
                    Reason for Rejection:
                  </CardBody>
                  <CardBody className={classes.reportText}>
                    {reason ? reason : ""}
                  </CardBody>
                  {done === false ? (
                    <CardBody>
                      <Button
                        onClick={() => {
                          const reportToRemediate = {
                            ...defaultGenericReport,
                            ...report.fishing_trip,
                            map_areas_id: report.map_areas_id
                          };
                          setInitialFormState(reportToRemediate);
                          setIsNewReport(true);
                          setPageOffset(1);
                          scrollToTop();
                        }}
                        className={classes.resubmitButton}
                      >
                        <Typography className={classes.rejectedButtonText}>
                          Edit and Resubmit New Report
                        </Typography>
                      </Button>
                      <Button
                        className={classes.markAsDoneButton}
                        fontSize="12px"
                        onClick={() => {
                          markAsDone(id);
                        }}
                      >
                        <Typography className={classes.rejectedButtonText}>
                          Mark as Done
                        </Typography>
                      </Button>
                    </CardBody>
                  ) : null}
                </Fragment>
              ) : null}
            </Card>
          );
        })}
      </>
    );
  }

  const defaultGenericReport = {
    ...initialState,
    vessel_license_number: account.vessel_license_number,
    account_id: account.id,
    crew_member: [
      {
        first_name: account.first_name,
        last_name: account.last_name,
        business_license_number: account.business_license_number
      }
    ]
  };

  return (
    <GridContainer
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <div
            className={
              pageOffset === 0 && showSubmittedReports !== true
                ? classes.visible
                : classes.hidden
            }
          >
            <div>
              <CardHeader className={classes.primaryHeader} title>
                <Typography className={classes.primaryCard} variant="h6">
                  <GridContainer>
                    <GridItem
                      xs={11}
                      onClick={() => {
                        setPageOffset(1);
                        scrollToTop();
                        setInitialFormState({
                          ...defaultGenericReport
                        });
                        setSavedFormValues({ ...defaultGenericReport });
                        setIsNewReport(true);
                      }}
                    >
                      New Fishing Report
                    </GridItem>
                    <GridItem xs={1}>
                      <Tooltip field="NEW_FISHING_REPORT_TOOLTIP" />
                    </GridItem>
                  </GridContainer>
                </Typography>
              </CardHeader>
            </div>
            <details className={classes.styledDetail}>
              <summary>
                <CardHeader className={classes.primaryHeader} title>
                  <Typography className={classes.primaryCard} variant="h6">
                    <GridContainer>
                      <GridItem xs={11}>
                        Pending Fishing Reports
                        <span className={classes.countSpan}>
                          {
                            fishingReports.filter(
                              fishingReport => !fishingReport.submitted_at
                            ).length
                          }
                        </span>
                      </GridItem>
                      <GridItem xs={1}>
                        <Tooltip field="PENDING_FISHING_REPORT_TOOLTIP" />
                      </GridItem>
                    </GridContainer>
                  </Typography>
                </CardHeader>
              </summary>

              {fishingReports.map((report, index) => {
                const fishingReport = {
                  ...report,
                  ...report.fishing_trip,
                  submitted_at: report.submitted_at,
                  is_approved: report.is_approved
                };
                if (!fishingReport.submitted_at) {
                  const {
                    departure_datetime,
                    arrival_datetime,
                    vessel_license_number,
                    crew_member,
                    fishing_events
                  } = fishingReport;
                  const totalFishingLbs = totalPoundsFromEvents(fishing_events);
                  return (
                    <div
                      className={classes.reportBox}
                      onClick={() => {
                        setSavedFormValues({
                          ...fishingReport,
                          ...fishingReport.fishing_trip
                        });
                        setInitialFormState({
                          ...fishingReport,
                          ...fishingReport.fishing_trip
                        });
                        setIsNewReport(false);
                        setCurrentReportId(fishingReport.id);
                        setPageOffset(1);
                        scrollToTop();
                      }}
                      key={`fishing-report-pending-item-${index}`}
                    >
                      <div className={classes.pendingCardHeader}>
                        <span>
                          {moment(departure_datetime).format("MMMM Do YYYY")} -{" "}
                          {moment(arrival_datetime).format("MMMM Do YYYY")}
                        </span>
                        <span className={classes.pendingCardStep}>
                          {formProgressStepUtil(fishingReport)}/4 Complete
                        </span>
                      </div>
                      <p>
                        {vessel_license_number} &middot;{" "}
                        {crew_member && crew_member.length === 1 && (
                          <span>{crew_member.length} Crew member</span>
                        )}
                        {crew_member && crew_member.length > 1 && (
                          <span>{crew_member.length} Crew members</span>
                        )}
                        {totalFishingLbs > 0 && (
                          <span>
                            {" "}
                            &middot; {totalFishingLbs} Estimated weight (in
                            pounds)
                          </span>
                        )}
                      </p>
                      <PendingReportsProgressBar
                        progreeBarWidth={formProgressWidthUtil(fishingReport)}
                      />
                    </div>
                  );
                }
              })}
            </details>
            <div className={classes.styledDetail}>
              <div>
                <CardHeader className={classes.primaryHeader} title>
                  <Typography className={classes.primaryCard} variant="h6">
                    <GridContainer>
                      <GridItem
                        onClick={() => {
                          setShowSubmittedReports(true);
                          setPageOffset(0);
                          scrollToTop();
                          setInitialFormState({
                            ...defaultGenericReport
                          });
                          setSavedFormValues({ ...defaultGenericReport });
                          setIsNewReport(true);
                        }}
                        xs={11}
                      >
                        Submitted Reports
                      </GridItem>
                      <GridItem xs={1}>
                        <Tooltip field="SUBMITTED_FISHING_REPORT_TOOLTIP" />
                      </GridItem>
                    </GridContainer>
                  </Typography>
                </CardHeader>
              </div>
            </div>
          </div>
          <div
            className={
              pageOffset === 0 && showSubmittedReports === true
                ? classes.visible
                : classes.hidden
            }
          >
            <Button
              className={classes.backButton}
              onClick={() => {
                scrollToTop();
                setShowSubmittedReports(false);
              }}
            >
              &#8592; Back
            </Button>
            <CardHeader title underline style={{ marginBottom: "1rem" }}>
              <Typography variant="h6" style={{ marginBottom: "8px" }}>
                Submitted Reports
              </Typography>
              <PeriodTabs>
                <Tab
                  active={selectedReportsTab === "rejected"}
                  onClick={() => setSetSelectedReportsTab("rejected")}
                >
                  Rejected
                </Tab>
                <Tab
                  active={selectedReportsTab === "pending"}
                  onClick={() => setSetSelectedReportsTab("pending")}
                >
                  Pending
                </Tab>
                <Tab
                  active={selectedReportsTab === "approved"}
                  onClick={() => setSetSelectedReportsTab("approved")}
                >
                  Approved
                </Tab>
              </PeriodTabs>
            </CardHeader>
            {renderFilteredFishingReports(fishingReports)}
          </div>
          <Form
            className={classes.reportsForm}
            initialValues={initialFormState}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              SchemaValidation[pageOffset - 1]
                .validate(values)
                .then(() => {
                  if (!navigator.onLine) {
                    saveReport({
                      newReport: isNewReport,
                      originalReport: { ...savedFormValues },
                      revisionReport: {
                        ...values,
                        is_approved: null,
                        id: currentReportId,
                        map_areas_id: currentReportMapArea
                          ? currentReportMapArea.id
                          : activeMapAreaId
                      },
                      id: currentReportId
                    });
                    setProgreeBarWidth("0%");
                    window.alert(
                      `Your device is currently offline. Please go to the pending reports section and submit your report once your device has regained connection to the internet`
                    );
                    setPageOffset(0);
                    scrollToTop();
                    actions.resetForm(defaultGenericReport);
                    return;
                  }

                  saveReport({
                    newReport: isNewReport,
                    originalReport: { ...savedFormValues },
                    revisionReport: {
                      ...values,
                      fishing_events: [...values.fishing_events],
                      submitted_at: new Date().toLocaleString(),
                      is_approved: null,
                      id: currentReportId,
                      map_areas_id: currentReportMapArea
                        ? currentReportMapArea.id
                        : activeMapAreaId
                    },
                    id: currentReportId
                  })
                    .then(() => {
                      setInitialFormState({
                        ...defaultGenericReport
                      });
                      actions.resetForm(defaultGenericReport);
                      if (navigator.onLine) {
                        window.alert(
                          "Your report has been submitted successfully.\nYou can check the status of this report by viewing Submitted Reports"
                        );
                      }
                      setPageOffset(0);
                      scrollToTop();
                    })
                    .catch(err => {
                      console.error(err);
                      if (!navigator.onLine) {
                        window.alert(
                          `Your device is currently offline. Please go to the pending reports section and submit your report once your device has regained connection to the internet`
                        );
                      } else {
                        window.alert(
                          "There has been an error submitting your report. Please try again later"
                        );
                      }
                    });
                })
                .catch(err => {
                  setReportError(err.message);
                });
            }}
            render={({
              values,
              handleSubmit,
              setFieldError,
              setFieldValue,
              errors,
              touched,
              resetForm
            }) => {
              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  {/********************************************/}
                  {/***** PAGE 1: Initial trip information *****/}
                  {/********************************************/}
                  <div
                    className={
                      pageOffset === 1 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        scrollToTop();
                        resetForm(defaultGenericReport);
                        window.location = "/app/fishing-reports";
                        navigate("/app/fishing-reports");
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Fishing Report
                      </Typography>
                    </CardHeader>

                    <Typography className={classes.smallHeader} variant="h6">
                      Trip Information
                    </Typography>

                    <InputLabel htmlFor="departure_datetime">
                      Departed
                    </InputLabel>
                    <div className={classes.fieldSpacingWrapper}>
                      <FormControl fullWidth>
                        <Input
                          id="departure_datetime"
                          name="departure_datetime"
                          placeholder="Month / Day / Year : Time"
                          type="datetime-local"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Field
                          id="departure_location"
                          name="departure_location"
                          placeholder="Port Name"
                          component="select"
                        >
                          <option selected="true" value={""}>
                            Select departure port..
                          </option>
                          {ports.map(portObj => {
                            return (
                              <option
                                value={portObj.port_name}
                                key={`departure-port-input-${portObj.id}`}
                              >
                                {portObj.port_name}
                              </option>
                            );
                          })}
                        </Field>
                        {errors.departure_location &&
                          touched.departure_location && (
                            <div>{errors.departure_location}</div>
                          )}
                      </FormControl>
                    </div>

                    <Divider className={classes.divider} />
                    <div>
                      <span>Vessel Information</span>
                    </div>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="vessel_license_number">
                        Vessel Registration Number
                      </InputLabel>
                      <Input
                        id="vessel_license_number"
                        name="vessel_license_number"
                        value={account.vessel_license_number}
                        readOnly
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="is_charter">
                        Was this a charter trip?
                      </InputLabel>
                      <RadioGroup
                        id="is_charter"
                        name="is_charter"
                        className={classes.radioGroup}
                        row={true}
                        value={`${values.is_charter}`}
                        onChange={e => {
                          setFieldValue(
                            "is_charter",
                            e.target.value === "true"
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth className={classes.bottomButtonBar}>
                      <div fullWidth>
                        <span>
                          Step {pageOffset}/{SchemaValidation.length}
                        </span>
                        {reportError && (
                          <span className={classes.errorSpan}>
                            {reportError}
                          </span>
                        )}
                      </div>
                      <div fullWidth>
                        <span>Next: Crew Information</span>

                        <Button
                          data-cy="submit"
                          className={classes.proceedButton}
                          onClick={() => {
                            const reportObject = {
                              newReport: isNewReport,
                              originalReport: { ...savedFormValues },
                              revisionReport: {
                                ...savedFormValues,
                                ...values,
                                fishing_events: [...values.fishing_events],
                                id: currentReportId,
                                map_areas_id: currentReportMapArea
                                  ? currentReportMapArea.id
                                  : activeMapAreaId
                              }
                            };
                            if (!isNewReport) {
                              reportObject.id = currentReportId;
                            }
                            if (isNewReport) {
                              reportObject.originalReport = {
                                ...values,
                                map_areas_id: values.map_areas_id
                                  ? values.map_areas_id
                                  : activeMapAreaId
                              };
                            }
                            SchemaValidation[pageOffset - 1]
                              .validate(values)
                              .then(valid => {
                                saveReport({ ...reportObject }).then(data => {
                                  setIsNewReport(false);
                                  setSavedFormValues({
                                    ...values
                                  });
                                  setCurrentReportId(data.id);
                                  setPageOffset(pageOffset + 1);
                                  scrollToTop();
                                  setReportError(null);
                                  setProgreeBarWidth(
                                    `${
                                      ((pageOffset + 1) /
                                        SchemaValidation.length) *
                                      100
                                    }%`
                                  );
                                });
                              })
                              .catch(err => {
                                setReportError(err.message);
                              });
                          }}
                        >
                          Save and continue
                        </Button>
                      </div>
                    </FormControl>
                  </div>

                  {/************************************/}
                  {/***** PAGE 2: Crew Information *****/}
                  {/************************************/}
                  <div
                    className={
                      pageOffset === 2 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Fishing Report
                      </Typography>
                      <HeaderSummary values={values} />
                    </CardHeader>
                    <Typography className={classes.smallHeader} variant="h6">
                      Crew Information{" "}
                      <Tooltip field="FISHING_REPORT_CREW_MEMBER_TOOLTIP" />
                      <span className={classes.crewCountContainer}>
                        <span>Total </span>
                        <span className={classes.crewCountSpan}>
                          {
                            values.crew_member.filter(
                              member =>
                                !!member.first_name || !!member.last_name
                            ).length
                          }
                        </span>
                      </span>
                    </Typography>

                    <Divider style={{ margin: "1rem 0" }} />
                    <p>
                      The catch of the crew will only be attributed to the
                      primary reporter if no other license is entered
                    </p>

                    <FieldArray
                      name="crew_member"
                      render={arrayHelpers => (
                        <div className={classes.crewContainer}>
                          {values.crew_member.map((crewMember, index) => {
                            if (index === 0) {
                              return (
                                <>
                                  <span>Your Information</span>
                                  <div
                                    key={`crew_member_${index}`}
                                    className={classes.fieldSpacingWrapper}
                                    style={{
                                      padding: "0.5rem",
                                      marginTop: "0.5rem"
                                    }}
                                  >
                                    <span className={classes.name}>
                                      {crewMember.first_name}{" "}
                                      {crewMember.last_name}{" "}
                                    </span>
                                    <span
                                      className={classes.vesselLicenseRight}
                                    >
                                      {crewMember.business_license_number}
                                    </span>
                                  </div>
                                </>
                              );
                            }
                            return (
                              <>
                                {index === 1 && (
                                  <Divider
                                    style={{ margin: "1rem 0 0.5rem" }}
                                  />
                                )}
                                <div
                                  data-cy="crew-wrapper"
                                  key={`crew_member_${index}`}
                                  className={classes.crewWrapper}
                                >
                                  <FormControl
                                    fullWidth
                                    style={{ marginTop: "0" }}
                                  >
                                    <Button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                      className={classes.removeCrewMemberBtn}
                                    >
                                      Remove
                                    </Button>
                                  </FormControl>
                                  <FormControl
                                    fullWidth
                                    style={{
                                      width: "50%",
                                      paddingRight: "6px"
                                    }}
                                  >
                                    <InputLabel
                                      htmlFor={`crew_member[${index}].first_name`}
                                    >
                                      First Name
                                    </InputLabel>

                                    <Input
                                      id={`crew_member[${index}].first_name`}
                                      name={`crew_member[${index}].first_name`}
                                      placeholder="First name"
                                    />
                                  </FormControl>
                                  <FormControl
                                    fullWidth
                                    style={{ width: "50%" }}
                                  >
                                    <InputLabel
                                      htmlFor={`crew_member[${index}].last_name`}
                                    >
                                      Last name
                                    </InputLabel>
                                    <Input
                                      id={`crew_member[${index}].last_name`}
                                      name={`crew_member[${index}].last_name`}
                                      placeholder="Last name"
                                    />
                                  </FormControl>

                                  <FormControl
                                    fullWidth
                                    style={{ width: "75%" }}
                                  >
                                    <InputLabel
                                      htmlFor={`crew_member[${index}].business_license_number`}
                                    >
                                      Fishing License Number
                                    </InputLabel>
                                    <Input
                                      id={`crew_member[${index}].business_license_number`}
                                      name={`crew_member[${index}].business_license_number`}
                                      placeholder="Fishing License Number"
                                    />
                                  </FormControl>
                                </div>
                              </>
                            );
                          })}
                          <FormControl fullWidth>
                            <Button
                              className={classes.buttonOutline}
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  first_name: "",
                                  last_name: "",
                                  business_license_number: ""
                                })
                              }
                            >
                              + Add crew member
                            </Button>
                          </FormControl>
                        </div>
                      )}
                    />

                    <FormControl fullWidth className={classes.bottomButtonBar}>
                      <div>
                        <span>
                          Step {pageOffset}/{SchemaValidation.length}
                        </span>
                        {reportError && (
                          <span className={classes.errorSpan}>
                            {reportError}
                          </span>
                        )}
                      </div>
                      <div>
                        <span>Next: Fishing &amp; Fishing Details</span>
                        <Button
                          data-cy="submit"
                          className={classes.proceedButton}
                          onClick={() => {
                            SchemaValidation[pageOffset - 1]
                              .validate(values)
                              .then(valid => {
                                saveReport({
                                  newReport: false,
                                  originalReport: {
                                    ...savedFormValues
                                  },
                                  revisionReport: {
                                    ...savedFormValues,
                                    ...values,
                                    fishing_events: [...values.fishing_events],
                                    id: currentReportId,
                                    map_areas_id: currentReportMapArea
                                      ? currentReportMapArea.id
                                      : activeMapAreaId
                                  },
                                  id: currentReportId
                                });
                                setPageOffset(pageOffset + 1);
                                setSavedFormValues(values);
                                scrollToTop();
                                setReportError(null);
                                setProgreeBarWidth(
                                  `${
                                    ((pageOffset + 1) /
                                      SchemaValidation.length) *
                                    100
                                  }%`
                                );
                              })
                              .catch(err => {
                                setReportError(err.message);
                              });
                          }}
                        >
                          Save and continue
                        </Button>
                      </div>
                    </FormControl>
                  </div>

                  {/*************************************/}
                  {/******* PAGE 3: Fishing Events ******/}
                  {/*************************************/}
                  <div
                    className={
                      pageOffset === 3 ? classes.visible : classes.hidden
                    }
                  >
                    {editingEventIndex < 0 && (
                      <div>
                        <Button
                          className={classes.backButton}
                          onClick={() => {
                            setPageOffset(pageOffset - 1);
                            scrollToTop();
                          }}
                        >
                          &#8592; Back
                        </Button>
                        <CardHeader
                          title
                          underline
                          style={{ marginBottom: "1rem" }}
                        >
                          <Typography
                            variant="h6"
                            style={{ marginBottom: "8px" }}
                          >
                            New Fishing Report
                          </Typography>
                          <HeaderSummary values={values} />
                        </CardHeader>

                        <div>
                          <div>
                            Fishing Events{" "}
                            <Tooltip field="FISHING_REPORT_FISHING_EVENT_TOOLTIP" />
                            <span style={{ float: "right" }}>
                              Total
                              <span className={classes.countSpan}>
                                {values.fishing_events.length}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {editingEventIndex < 0 &&
                      values.fishing_events.map(
                        (fishingEvent, fishingEventIndex) => {
                          return (
                            <div
                              className={classes.fieldSpacingWrapper}
                              style={{
                                position: "relative",
                                margin: "0.5rem 0"
                              }}
                            >
                              <div>
                                <span style={{ fontSize: "1rem" }}>
                                  {fishingEvent.description}
                                </span>
                                <details className={classes.eventOptions}>
                                  <summary>Options</summary>
                                  <p
                                    className={classes.eventOptionItems}
                                    onClick={() => {
                                      const currentFishingMethodForFishingEvent =
                                        values.fishing_events[fishingEventIndex]
                                          .fishing_method_id;

                                      if (currentFishingMethodForFishingEvent) {
                                        setDraftFishingMethodId(
                                          currentFishingMethodForFishingEvent
                                        );
                                      }

                                      setEditingEventIndex(fishingEventIndex);
                                    }}
                                  >
                                    Edit
                                  </p>
                                  {fishingEventIndex > 0 && (
                                    <p
                                      className={classes.eventOptionItems}
                                      onClick={() => {
                                        setEditingEventIndex(fishingEventIndex);
                                        setShowDeleteEventModal(true);
                                      }}
                                    >
                                      Remove Event
                                    </p>
                                  )}
                                </details>
                              </div>
                              <div>
                                <p>
                                  {fishingEvent.description ? (
                                    <span>
                                      {fishingEvent.description} &middot;{" "}
                                    </span>
                                  ) : null}
                                  {findFishingMethod(
                                    fishingEvent.fishing_method_id
                                  )}{" "}
                                  &middot;{" "}
                                  {moment(fishingEvent.start_time).format(
                                    "MMMM Do YYYY, h:mm A"
                                  )}{" "}
                                  &middot; {fishingEvent.fishing_hours}hrs{" "}
                                  &middot;{" "}
                                  {speciesListFromEvent(fishingEvent, species)}{" "}
                                  &middot; {totalPoundsFromEvent(fishingEvent)}{" "}
                                  Estimated weight (in pounds)
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}

                    <FieldArray
                      name="fishing_events"
                      render={arrayHelpers => {
                        return (
                          <div>
                            {values.fishing_events.map(
                              (fishingEvent, fishingEventIndex) => {
                                const shouldRender =
                                  editingEventIndex === fishingEventIndex;

                                if (!shouldRender) {
                                  return false;
                                }

                                return (
                                  <div
                                    className={
                                      editingEventIndex === fishingEventIndex
                                        ? classes.visibleEvent
                                        : classes.hidden
                                    }
                                    key={`fishing_events_${fishingEventIndex}${draftFishingMethodId}${selectedFishingEventAreaIndex}`}
                                  >
                                    <FormControl fullWidth>
                                      Fishing Method
                                      <Field
                                        id={`fishing_events[${fishingEventIndex}].fishing_method_id`}
                                        name={`fishing_events[${fishingEventIndex}].fishing_method_id`}
                                        component="select"
                                        onChange={e => {
                                          const nextFishingMethodId =
                                            e.target.value;
                                          setDraftFishingMethodId(
                                            nextFishingMethodId
                                          );
                                          setDraftFishingAreaIds([]);
                                          setFieldValue(
                                            `fishing_events[${fishingEventIndex}].fishing_method_id`,
                                            nextFishingMethodId
                                          );
                                          setFieldValue(
                                            `fishing_events[${fishingEventIndex}].fished_areas_ids`,
                                            "[]"
                                          );
                                        }}
                                      >
                                        <option selected value="" disabled>
                                          Select fishing method
                                        </option>
                                        {fishingMethods.map(
                                          (fishingMethod, itemIndex) => (
                                            <option
                                              value={fishingMethod.id}
                                              key={`fishing-method-${itemIndex}`}
                                            >
                                              {fishingMethod.type}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </FormControl>
                                    {(values.fishing_events[fishingEventIndex]
                                      .fishing_method_id ==
                                      TROLLING_METHOD_ID ||
                                      values.fishing_events[fishingEventIndex]
                                        .fishing_method_id ==
                                        BOTTOMFISHING_METHOD_ID) && (
                                      <>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            htmlFor={`fishing_events[${fishingEventIndex}].number_of_gears`}
                                          >
                                            Number of Gears
                                          </InputLabel>
                                          <Select
                                            id={`fishing_events[${fishingEventIndex}].number_of_gears`}
                                            name={`fishing_events[${fishingEventIndex}].number_of_gears`}
                                            onChange={(name, value) =>
                                              setFieldValue(name, value)
                                            }
                                            defaultValue={null}
                                          >
                                            <option
                                              value={null}
                                              key={`gear-option-default`}
                                              disabled
                                              selected
                                            >
                                              {" "}
                                              Choose number of gears
                                            </option>

                                            {[...Array(10).keys()].map(
                                              number => {
                                                return (
                                                  <option
                                                    value={number + 1}
                                                    key={`gear-option-${number}`}
                                                  >
                                                    {number + 1}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </>
                                    )}
                                    {values.fishing_events[fishingEventIndex]
                                      .fishing_method_id ==
                                      SPEARFISHING_METHOD_ID && (
                                      <>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            htmlFor={`fishing_events[${fishingEventIndex}].number_of_spears`}
                                          >
                                            Number of Spears/Divers
                                          </InputLabel>
                                          <Select
                                            id={`fishing_events[${fishingEventIndex}].number_of_spears`}
                                            name={`fishing_events[${fishingEventIndex}].number_of_spears`}
                                            onChange={(name, value) =>
                                              setFieldValue(name, value)
                                            }
                                            defaultValue={null}
                                          >
                                            <option
                                              value={null}
                                              key={`spear-option-default`}
                                              disabled
                                              selected
                                            >
                                              Choose number of spears
                                            </option>
                                            {[...Array(10).keys()].map(
                                              number => {
                                                return (
                                                  <option
                                                    value={number + 1}
                                                    key={`spear-option-${number}`}
                                                  >
                                                    {number + 1}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </>
                                    )}

                                    <FormControl
                                      fullWidth
                                      style={{ zIndex: "2000" }}
                                    >
                                      <InputLabel>Target Species</InputLabel>
                                      <Field
                                        id={`fishing_events[${fishingEventIndex}].target_species`}
                                        name={`fishing_events[${fishingEventIndex}].target_species`}
                                        component={SelectSearch}
                                        isSearchable={true}
                                        options={species
                                          .filter(item => {
                                            return (
                                              item.caught_by ===
                                                findFishingMethod(
                                                  values.fishing_events[
                                                    fishingEventIndex
                                                  ].fishing_method_id
                                                ) ||
                                              item.common_name ===
                                                UNKNOWN_SPECIES ||
                                              item.caught_by ===
                                                REEF_FISH_METHOD ||
                                              item.secondary_caught_by ===
                                                findFishingMethod(
                                                  values.fishing_events[
                                                    fishingEventIndex
                                                  ].fishing_method_id
                                                )
                                            );
                                          })
                                          .map(species => {
                                            return {
                                              value: species.id,
                                              label: species.common_name
                                            };
                                          })}
                                        isMulti={true}
                                      />
                                    </FormControl>
                                    <FormControl fullWidth>
                                      <div
                                        className={
                                          classes.inputLabelWithToolTipWrapper
                                        }
                                      >
                                        <InputLabel
                                          htmlFor={`fishing_events[${fishingEventIndex}].fishing_hours`}
                                        >
                                          Fishing Hours
                                        </InputLabel>
                                        <Tooltip field="FISHING_REPORT_FISHING_HOURS_TOOLTIP" />
                                      </div>
                                      <Input
                                        id={`fishing_events[${fishingEventIndex}].fishing_hours`}
                                        name={`fishing_events[${fishingEventIndex}].fishing_hours`}
                                        placeholder="Fishing Hours"
                                        type="number"
                                        onBlur={e => {
                                          setFieldValue(
                                            `fishing_events[${fishingEventIndex}].fishing_hours`,
                                            formatFloat(e.target.value)
                                          );
                                        }}
                                        inputProps={{
                                          step: "0.01",
                                          min: "0"
                                        }}
                                      />
                                    </FormControl>

                                    <Typography
                                      className={classes.reportLabel}
                                      color="textPrimary"
                                    >
                                      {`Map: ${
                                        currentReportMapArea
                                          ? currentReportMapArea.name
                                          : null
                                      }`}{" "}
                                      <Tooltip field="FISHING_REPORT_MAP_AREAS_TOOLTIP" />
                                    </Typography>
                                    <div
                                      style={{
                                        height: "200px",
                                        width: "100%",
                                        position: "relative"
                                      }}
                                    >
                                      {pageOffset === 3 && (
                                        <FishedAreasPreviewMap
                                          defaultMapCenter={defaultMapCenter}
                                          fishingEventIndex={fishingEventIndex}
                                          mapPinCoordinates={mapPinCoordinates}
                                          setSelectedFishingEventAreaIndex={
                                            setSelectedFishingEventAreaIndex
                                          }
                                          selectedFishingEventAreaIndex={
                                            selectedFishingEventAreaIndex
                                          }
                                          setPageOffset={setPageOffset}
                                          setFieldValue={setFieldValue}
                                        />
                                      )}
                                    </div>

                                    <FormControl fullWidth>
                                      <Input
                                        id={`fishing_events[${fishingEventIndex}].fished_areas_ids`}
                                        name={`fishing_events[${fishingEventIndex}].fished_areas_ids`}
                                        placeholder="Fishing Areas"
                                        value={`${values.fishing_events[fishingEventIndex].fished_areas_ids}`}
                                        type="hidden"
                                      />
                                    </FormControl>

                                    <FieldArray
                                      name={`fishing_events[${fishingEventIndex}].catches`}
                                      render={arrayHelpers2 => {
                                        return (
                                          <div>
                                            {fishingEvent.catches.map(
                                              (catchItem, catchItemIndex) => {
                                                return (
                                                  <div
                                                    key={`catch_item_${catchItemIndex}`}
                                                  >
                                                    <div
                                                      className={
                                                        classes.inputLabelWithToolTipWrapper
                                                      }
                                                    >
                                                      <InputLabel
                                                        htmlFor={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].species_id`}
                                                      >
                                                        Fish Caught{" "}
                                                      </InputLabel>
                                                      <Tooltip field="FISHING_REPORT_FISH_CAUGHT_TOOLTIP" />
                                                    </div>
                                                    <FormControl fullWidth>
                                                      <Field
                                                        id={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].species_id`}
                                                        name={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].species_id`}
                                                        component={SelectSearch}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={species.map(
                                                          item => {
                                                            return {
                                                              value: item.id,
                                                              label:
                                                                item.common_name
                                                            };
                                                          }
                                                        )}
                                                      />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                      <InputLabel
                                                        htmlFor={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_count`}
                                                      >
                                                        Total number of pieces
                                                      </InputLabel>
                                                      <Input
                                                        id={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_count`}
                                                        name={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_count`}
                                                        placeholder="Total number of pieces"
                                                        type="number"
                                                        onBlur={e => {
                                                          setFieldValue(
                                                            `fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_count`,
                                                            Math.abs(
                                                              Math.round(
                                                                e.target.value
                                                              )
                                                            )
                                                          );
                                                        }}
                                                        inputProps={{
                                                          min: "0"
                                                        }}
                                                      />
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                      <InputLabel
                                                        htmlFor={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_weight_lbs`}
                                                      >
                                                        Estimated Total Weight
                                                        (in pounds)
                                                      </InputLabel>
                                                      <Input
                                                        id={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_weight_lbs`}
                                                        name={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_weight_lbs`}
                                                        placeholder="Total weight"
                                                        type="number"
                                                        onBlur={e => {
                                                          setFieldValue(
                                                            `fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].total_weight_lbs`,
                                                            formatFloat(
                                                              e.target.value
                                                            )
                                                          );
                                                        }}
                                                        inputProps={{
                                                          step: "0.01",
                                                          min: "0"
                                                        }}
                                                      />
                                                    </FormControl>

                                                    <FormControl fullWidth>
                                                      {errors.fishing_events &&
                                                        errors.fishing_events[
                                                          fishingEventIndex
                                                        ] &&
                                                        errors.fishing_events[
                                                          fishingEventIndex
                                                        ].catches[
                                                          catchItemIndex
                                                        ] &&
                                                        errors.fishing_events[
                                                          fishingEventIndex
                                                        ].catches[
                                                          catchItemIndex
                                                        ].attachments && (
                                                          <div
                                                            style={{
                                                              color: "red"
                                                            }}
                                                          >
                                                            {
                                                              errors
                                                                .fishing_events[
                                                                fishingEventIndex
                                                              ].catches[
                                                                catchItemIndex
                                                              ].attachments
                                                            }
                                                          </div>
                                                        )}
                                                      <div>
                                                        <span
                                                          className={
                                                            classes.reportLabel
                                                          }
                                                        >
                                                          Photos of catch (5
                                                          maximum){" "}
                                                        </span>
                                                        <Tooltip field="FISHING_REPORT_FISH_PHOTOS_TOOLTIP" />
                                                      </div>
                                                      <ImageUpload
                                                        id={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].attachments`}
                                                        name={`fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].attachments`}
                                                        buttonClassName={
                                                          classes.buttonOutline
                                                        }
                                                        buttonText="Upload From Album"
                                                        multiple={true}
                                                        limit={
                                                          MAX_FISHING_CATCH_UPLOADS
                                                        }
                                                        uploadedFiles={
                                                          values.fishing_events[
                                                            fishingEventIndex
                                                          ].catches[
                                                            catchItemIndex
                                                          ].attachments
                                                        }
                                                        handleFileChange={(
                                                          files,
                                                          err
                                                        ) => {
                                                          if (err) {
                                                            setFieldError(
                                                              `fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].attachments`,
                                                              err
                                                            );
                                                            return;
                                                          }

                                                          setFieldValue(
                                                            `fishing_events[${fishingEventIndex}].catches[${catchItemIndex}].attachments`,
                                                            files
                                                          );
                                                        }}
                                                      />
                                                    </FormControl>
                                                    {catchItemIndex !== 0 && (
                                                      <FormControl fullWidth>
                                                        <Button
                                                          className={
                                                            classes.buttonOutline
                                                          }
                                                          type="button"
                                                          onClick={() => {
                                                            arrayHelpers2.remove(
                                                              catchItemIndex
                                                            );
                                                          }}
                                                        >
                                                          Remove fishing detail
                                                        </Button>
                                                      </FormControl>
                                                    )}

                                                    <Divider
                                                      className={
                                                        classes.divider
                                                      }
                                                    />

                                                    {catchItemIndex ===
                                                      values.fishing_events[
                                                        fishingEventIndex
                                                      ].catches.length -
                                                        1 && (
                                                      <FormControl fullWidth>
                                                        <Button
                                                          className={
                                                            classes.buttonOutline
                                                          }
                                                          type="button"
                                                          onClick={() => {
                                                            arrayHelpers2.push(
                                                              initialFishingEventCatches
                                                            );
                                                          }}
                                                        >
                                                          + Add Species Caught
                                                        </Button>
                                                      </FormControl>
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }}
                                    />

                                    <FormControl fullWidth>
                                      <InputLabel
                                        htmlFor={`fishing_events[${fishingEventIndex}].description`}
                                      >
                                        Notes
                                      </InputLabel>
                                      <Input
                                        id={`fishing_events[${fishingEventIndex}].description`}
                                        name={`fishing_events[${fishingEventIndex}].description`}
                                        rows="4"
                                        inputProps={{
                                          maxLength: 256
                                        }}
                                        multiline
                                      />
                                    </FormControl>

                                    {showDeleteEventModal &&
                                      editingEventIndex ===
                                        fishingEventIndex && (
                                        <PopupModal
                                          open={true}
                                          onCloseModal={() => {
                                            setEditingEventIndex(-1);
                                            setShowDeleteEventModal(false);
                                          }}
                                        >
                                          <h2>
                                            Are you sure you want to remove
                                            Fishing Event{" "}
                                            {fishingEventIndex + 1}
                                          </h2>
                                          <Button
                                            className={
                                              classes.buttonModalOptions
                                            }
                                            onClick={() => {
                                              setShowDeleteEventModal(false);
                                              arrayHelpers.remove(
                                                fishingEventIndex
                                              );
                                              setEditingEventIndex(-1);
                                            }}
                                          >
                                            Ok
                                          </Button>
                                          <Button
                                            className={
                                              classes.buttonModalOptions
                                            }
                                            onClick={() => {
                                              setShowDeleteEventModal(false);
                                              setEditingEventIndex(-1);
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                        </PopupModal>
                                      )}
                                    <FormControl fullWidth>
                                      <Button
                                        data-cy="submit"
                                        className={classes.submitBtn}
                                        type="button"
                                        onClick={() => {
                                          if (
                                            values.fishing_events.length > 0
                                          ) {
                                            SchemaValidation[pageOffset - 1]
                                              .validate(values)
                                              .then(valid => {
                                                setSavedFormValues(values);
                                                const eventSpeciesIds = Object.keys(
                                                  groupedFishingDetailsFromEvents(
                                                    values.fishing_events
                                                  )
                                                );
                                                let formEventValues = [
                                                  ...values.fishing_events
                                                ];
                                                setEditingEventIndex(-1);
                                                saveReport({
                                                  newReport: false,
                                                  originalReport: savedFormValues,
                                                  revisionReport: {
                                                    ...savedFormValues,
                                                    ...values,
                                                    fishing_events: [
                                                      ...formEventValues
                                                    ],
                                                    id: currentReportId,
                                                    map_areas_id: currentReportMapArea
                                                      ? currentReportMapArea.id
                                                      : activeMapAreaId
                                                  },
                                                  id: currentReportId
                                                });
                                                setReportError(null);
                                                setProgreeBarWidth(
                                                  `${
                                                    ((pageOffset + 1) /
                                                      SchemaValidation.length) *
                                                    100
                                                  }%`
                                                );
                                                setSalesInformationSelectableFishIds(
                                                  eventSpeciesIds
                                                );
                                                if (
                                                  values.sales_order[0] &&
                                                  values.sales_order[0].items &&
                                                  values.sales_order[0].items
                                                    .length > 0
                                                ) {
                                                  values.sales_order[0].items.forEach(
                                                    (item, itemIndex) => {
                                                      if (
                                                        !eventSpeciesIds.includes(
                                                          `${item.species_id}`
                                                        )
                                                      ) {
                                                        setFieldValue(
                                                          `sales_order[0].items[${itemIndex}].species_id`,
                                                          ""
                                                        );
                                                      }
                                                    }
                                                  );
                                                }
                                              })
                                              .catch(err => {
                                                let errorMessage = err.message;
                                                if (
                                                  errorMessage.includes(
                                                    "fishing_method_id"
                                                  )
                                                ) {
                                                  errorMessage =
                                                    "Fishing method is required";
                                                }
                                                if (
                                                  errorMessage.includes(
                                                    "species_id"
                                                  )
                                                ) {
                                                  errorMessage =
                                                    "Species required";
                                                }
                                                if (
                                                  errorMessage.includes(
                                                    "fished_areas"
                                                  )
                                                ) {
                                                  errorMessage =
                                                    "Fished areas is required";
                                                }
                                                setReportError(errorMessage);
                                              });
                                          }
                                        }}
                                      >
                                        Save Fishing Event
                                      </Button>
                                      {reportError && (
                                        <span
                                          className={classes.errorSpan}
                                          style={{ margin: "auto" }}
                                        >
                                          {reportError}
                                        </span>
                                      )}
                                    </FormControl>
                                  </div>
                                );
                              }
                            )}

                            {editingEventIndex < 0 && (
                              <FormControl fullWidth>
                                <Button
                                  className={classes.buttonOutline}
                                  type="button"
                                  onClick={() => {
                                    setSavedFormValues({
                                      ...values,
                                      fishing_events: [
                                        ...values.fishing_events,
                                        { ...initialFishingEvent }
                                      ]
                                    });
                                    arrayHelpers.push({
                                      ...initialFishingEvent
                                    });
                                    setEditingEventIndex(
                                      values.fishing_events.length
                                    );
                                  }}
                                >
                                  + Add fishing event
                                </Button>
                              </FormControl>
                            )}
                          </div>
                        );
                      }}
                    />

                    {editingEventIndex < 0 && (
                      <FormControl
                        fullWidth
                        className={classes.bottomButtonBar}
                      >
                        <div>
                          <span>
                            Step {pageOffset}/{SchemaValidation.length}
                          </span>
                          {reportError && (
                            <span className={classes.errorSpan}>
                              {reportError}
                            </span>
                          )}
                        </div>
                        <div>
                          <span>Next: Fish Lost or Released</span>
                          {values.fishing_events.length !== 0 && (
                            <Button
                              data-cy="submit"
                              className={classes.proceedButton}
                              onClick={() => {
                                setPageOffset(pageOffset + 1);
                                scrollToTop();
                              }}
                            >
                              Save and continue
                            </Button>
                          )}
                        </div>
                      </FormControl>
                    )}
                  </div>

                  {/**************************************/}
                  {/*** PAGE 4: Fish Lost or Released ****/}
                  {/**************************************/}
                  <div
                    className={
                      pageOffset === 4 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Fishing Report
                      </Typography>
                      <HeaderSummary values={values} />
                    </CardHeader>

                    <FieldArray
                      name="fish_lost"
                      render={arrayHelpers => (
                        <div>
                          <div>
                            <Typography
                              className={classes.smallHeader}
                              variant="h6"
                            >
                              Fish Lost or Released
                            </Typography>
                            <div style={{ marginBottom: "0.5rem" }}>
                              If no fish were lost or released for this trip...
                              <span
                                className={classes.skipSales}
                                onClick={() => setPageOffset(pageOffset + 1)}
                              >
                                skip this step ⟶
                              </span>
                            </div>
                          </div>
                          {values.fish_lost.map((fish_lost, lostIndex) => {
                            return (
                              <div
                                key={`fish_lost_${lostIndex}`}
                                className={classes.fieldSpacingWrapper}
                              >
                                <InputLabel
                                  htmlFor={`fish_lost[${lostIndex}].species_id`}
                                >
                                  Species
                                </InputLabel>
                                <FormControl fullWidth>
                                  <Field
                                    id={`fish_lost[${lostIndex}].species_id`}
                                    name={`fish_lost[${lostIndex}].species_id`}
                                    component={SelectSearch}
                                    isSearchable={true}
                                    options={species.map(item => {
                                      return {
                                        value: item.id,
                                        label: item.common_name
                                      };
                                    })}
                                  />
                                </FormControl>

                                <FormControl
                                  style={{
                                    width: "50%",
                                    paddingRight: "6px"
                                  }}
                                >
                                  <InputLabel
                                    htmlFor={`fish_lost[${lostIndex}].total_count_lost`}
                                  >
                                    Count Lost/Released
                                  </InputLabel>
                                  <Input
                                    id={`fish_lost[${lostIndex}].total_count_lost`}
                                    name={`fish_lost[${lostIndex}].total_count_lost`}
                                    placeholder="Total number of pieces"
                                    type="number"
                                    onBlur={e => {
                                      setFieldValue(
                                        `fish_lost[${lostIndex}].total_count_lost`,
                                        Math.abs(Math.round(e.target.value))
                                      );
                                    }}
                                    inputProps={{
                                      min: "0"
                                    }}
                                  />
                                </FormControl>

                                <FormControl
                                  style={{
                                    width: "50%"
                                  }}
                                >
                                  <InputLabel
                                    htmlFor={`fish_lost[${lostIndex}].total_weight_lbs_lost`}
                                  >
                                    Estimated Weight
                                  </InputLabel>
                                  <Input
                                    id={`fish_lost[${lostIndex}].total_weight_lbs_lost`}
                                    name={`fish_lost[${lostIndex}].total_weight_lbs_lost`}
                                    placeholder="Total weight"
                                    type="number"
                                    onBlur={e => {
                                      setFieldValue(
                                        `fish_lost[${lostIndex}].total_weight_lbs_lost`,
                                        formatFloat(e.target.value)
                                      );
                                    }}
                                    inputProps={{
                                      step: "0.01",
                                      min: "0"
                                    }}
                                  />
                                </FormControl>

                                <FormControl fullWidth>
                                  <InputLabel
                                    htmlFor={`fish_lost[${lostIndex}].lost_to_predator`}
                                  >
                                    How were these fish lost?
                                  </InputLabel>
                                  <RadioGroup
                                    id={`fish_lost[${lostIndex}].lost_to_predator`}
                                    name={`fish_lost[${lostIndex}].lost_to_predator`}
                                    className={classes.radioGroup}
                                    row={true}
                                    value={`${values.fish_lost[lostIndex].lost_to_predator}`}
                                    onChange={e => {
                                      setFieldValue(
                                        `fish_lost[${lostIndex}].lost_to_predator`,
                                        e.target.value === "true"
                                      );
                                      if (e.target.value === "true") {
                                        setFieldValue(
                                          `fish_lost[${lostIndex}].disposition`,
                                          null
                                        );
                                      }
                                      if (!e.target.value === "true") {
                                        setFieldValue(
                                          `fish_lost[${lostIndex}].predator_species_id`,
                                          null
                                        );
                                      }
                                      setReportError("");
                                    }}
                                  >
                                    <FormControlLabel
                                      value="true"
                                      control={<Radio color="primary" />}
                                      label="Lost to predator"
                                    />
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio color="primary" />}
                                      label="Released"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                {typeof values.fish_lost[lostIndex]
                                  .lost_to_predator === "boolean" &&
                                  !values.fish_lost[lostIndex]
                                    .lost_to_predator && (
                                    <FormControl fullWidth>
                                      <InputLabel
                                        htmlFor={`fish_lost[${lostIndex}].disposition`}
                                      >
                                        Disposition
                                      </InputLabel>
                                      <RadioGroup
                                        id={`fish_lost[${lostIndex}].disposition`}
                                        name={`fish_lost[${lostIndex}].disposition`}
                                        className={classes.radioGroup}
                                        row={true}
                                        value={`${values.fish_lost[lostIndex].disposition}`}
                                        onChange={e => {
                                          setFieldValue(
                                            `fish_lost[${lostIndex}].disposition`,
                                            e.target.value
                                          );
                                        }}
                                        style={{
                                          flexDirection: "column"
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Released Alive"
                                          control={<Radio color="primary" />}
                                          label="Released Alive"
                                        />
                                        <FormControlLabel
                                          value="Released Dead"
                                          control={<Radio color="primary" />}
                                          label="Released Dead"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  )}

                                {values.fish_lost[lostIndex]
                                  .lost_to_predator && (
                                  <>
                                    <InputLabel
                                      htmlFor={`fish_lost[${lostIndex}].predator_species_id`}
                                    >
                                      Predator Species
                                    </InputLabel>
                                    <FormControl fullWidth>
                                      <Field
                                        id={`fish_lost[${lostIndex}].predator_species_id`}
                                        name={`fish_lost[${lostIndex}].predator_species_id`}
                                        component={SelectSearch}
                                        isSearchable={true}
                                        options={
                                          species &&
                                          species
                                            .filter(item => {
                                              return (
                                                item.common_name ===
                                                  UNKNOWN_SPECIES ||
                                                item.caught_by ===
                                                  PREDATOR_SPECIES_CATEGORY ||
                                                item.secondary_caught_by ===
                                                  PREDATOR_SPECIES_CATEGORY
                                              );
                                            })
                                            .map((item, itemIndex) => {
                                              return {
                                                value: item.id,
                                                label: item.common_name
                                              };
                                            })
                                        }
                                      />
                                    </FormControl>
                                  </>
                                )}

                                <FormControl>
                                  <Button
                                    className={classes.buttonOutline}
                                    style={{ margin: "auto" }}
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.remove(lostIndex)
                                    }
                                  >
                                    Remove Detail
                                  </Button>
                                </FormControl>
                              </div>
                            );
                          })}
                          <FormControl fullWidth>
                            <Button
                              className={classes.buttonOutline}
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  ...initialLostFishState
                                })
                              }
                            >
                              + Add Loss or Release Detail
                            </Button>
                          </FormControl>
                        </div>
                      )}
                    />
                    <FormControl fullWidth className={classes.bottomButtonBar}>
                      <div>
                        <span>
                          Step {pageOffset}/{SchemaValidation.length}
                        </span>
                        {reportError && (
                          <span className={classes.errorSpan}>
                            {reportError}
                          </span>
                        )}
                      </div>
                      <div>
                        <span>Next: Arrival Information</span>

                        <Button
                          data-cy="submit"
                          className={classes.proceedButton}
                          onClick={() => {
                            if (values.fish_lost && values.fish_lost.length) {
                              SchemaValidation[pageOffset - 1]
                                .validate(values)
                                .then(valid => {
                                  setProgreeBarWidth(
                                    `${
                                      ((pageOffset + 1) /
                                        SchemaValidation.length) *
                                      100
                                    }%`
                                  );

                                  saveReport({
                                    newReport: false,
                                    originalReport: savedFormValues,
                                    revisionReport: {
                                      ...savedFormValues,
                                      ...values,
                                      id: currentReportId,
                                      map_areas_id: currentReportMapArea
                                        ? currentReportMapArea.id
                                        : activeMapAreaId
                                    },
                                    id: currentReportId
                                  });
                                  setPageOffset(pageOffset + 1);
                                  setSavedFormValues(values);
                                  scrollToTop();
                                  setReportError("");
                                })
                                .catch(err => {
                                  setReportError(err.message);
                                });
                            } else {
                              setPageOffset(pageOffset + 1);
                              scrollToTop();
                              setReportError("");
                            }
                          }}
                        >
                          {values.fish_lost && values.fish_lost.length ? (
                            <span>Save &amp; continue</span>
                          ) : (
                            <span>Skip &amp; continue </span>
                          )}
                        </Button>
                      </div>
                    </FormControl>
                  </div>

                  {/********************************************/}
                  {/***** PAGE 5: Arrival trip information *****/}
                  {/********************************************/}
                  <div
                    className={
                      pageOffset === 5 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Fishing Report
                      </Typography>
                      <HeaderSummary values={values} />
                    </CardHeader>

                    <Typography className={classes.smallHeader} variant="h6">
                      Trip Information
                    </Typography>

                    <InputLabel htmlFor="arrival_datetime">Returned</InputLabel>
                    <div className={classes.fieldSpacingWrapper}>
                      <FormControl fullWidth>
                        <Input
                          id="arrival_datetime"
                          name="arrival_datetime"
                          placeholder="Month / Day / Year : Time"
                          type="datetime-local"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Field
                          id="arrival_location"
                          name="arrival_location"
                          placeholder="Port Name"
                          component="select"
                        >
                          <option selected="true" value={""}>
                            Select arrival port..
                          </option>
                          {ports.map(portObj => {
                            return (
                              <option
                                value={portObj.port_name}
                                key={`departure-port-input-${portObj.id}`}
                              >
                                {portObj.port_name}
                              </option>
                            );
                          })}
                        </Field>
                      </FormControl>
                    </div>

                    <FormControl fullWidth className={classes.bottomButtonBar}>
                      <div fullWidth>
                        <div>
                          <span>
                            Step {pageOffset}/{SchemaValidation.length}
                          </span>
                          {reportError && (
                            <span className={classes.errorSpan}>
                              {reportError}
                            </span>
                          )}
                        </div>
                        <div>
                          <span>Next: Sales Information</span>

                          <Button
                            data-cy="submit"
                            className={classes.proceedButton}
                            onClick={() => {
                              const reportObject = {
                                newReport: isNewReport,
                                originalReport: { ...savedFormValues },
                                revisionReport: {
                                  ...savedFormValues,
                                  ...values,
                                  fishing_events: [...values.fishing_events],
                                  id: currentReportId
                                }
                              };
                              if (!isNewReport) {
                                reportObject.id = currentReportId;
                              }
                              if (isNewReport) {
                                reportObject.originalReport = { ...values };
                              }
                              SchemaValidation[pageOffset - 1]
                                .validate(values)
                                .then(valid => {
                                  saveReport({ ...reportObject }).then(data => {
                                    setIsNewReport(false);
                                    setSavedFormValues({
                                      ...values
                                    });
                                    setCurrentReportId(data.id);
                                    setPageOffset(pageOffset + 1);
                                    scrollToTop();
                                    setReportError(null);
                                    setProgreeBarWidth(
                                      `${
                                        ((pageOffset + 1) /
                                          SchemaValidation.length) *
                                        100
                                      }%`
                                    );
                                  });
                                })
                                .catch(err => {
                                  setReportError(err.message);
                                });
                            }}
                          >
                            Save and continue
                          </Button>
                        </div>
                      </div>
                    </FormControl>
                  </div>

                  {/**************************************/}
                  {/***** PAGE 6: Sales Information ******/}
                  {/**************************************/}
                  <div
                    className={
                      pageOffset === 6 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Fishing Report
                      </Typography>
                      <HeaderSummary values={values} />
                    </CardHeader>

                    <FieldArray
                      name="sales_order"
                      render={arrayHelpers => (
                        <div>
                          <div>
                            <Typography
                              className={classes.smallHeader}
                              variant="h6"
                            >
                              Sales Information
                            </Typography>
                            <div style={{ marginBottom: "0.5rem" }}>
                              If no sales were recorded for this trip, you can{" "}
                              <span
                                className={classes.skipSales}
                                onClick={() => setPageOffset(pageOffset + 1)}
                              >
                                skip this step and review your trip information
                                ⟶
                              </span>
                            </div>
                          </div>
                          {values.sales_order.map((sale, saleIndex) => {
                            return (
                              <div key={`sales_information_${saleIndex}`}>
                                <FieldArray
                                  name={`sales_order[${saleIndex}].items`}
                                  render={arrayHelpers2 => {
                                    return (
                                      <div>
                                        {values.sales_order[
                                          saleIndex
                                        ].items.map(
                                          (orderItem, orderItemIndex) => {
                                            return (
                                              <div
                                                key={`order_${orderItemIndex}`}
                                                className={
                                                  classes.fieldSpacingWrapper
                                                }
                                              >
                                                <InputLabel
                                                  htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].species_id`}
                                                >
                                                  Species
                                                </InputLabel>
                                                <FormControl fullWidth>
                                                  <Field
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].species_id`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].species_id`}
                                                    component={SelectSearch}
                                                    isSearchable={true}
                                                    options={
                                                      species &&
                                                      species
                                                        .filter(speciesItem => {
                                                          return salesInformationSelectableFishIds.includes(
                                                            `${speciesItem.id}`
                                                          );
                                                        })
                                                        .map(
                                                          (item, itemIndex) => {
                                                            return {
                                                              value: item.id,
                                                              label:
                                                                item.common_name
                                                            };
                                                          }
                                                        )
                                                    }
                                                  />
                                                </FormControl>

                                                <FormControl
                                                  style={{
                                                    width: "50%",
                                                    paddingRight: "6px"
                                                  }}
                                                >
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].total_count`}
                                                  >
                                                    No. pieces sold
                                                  </InputLabel>
                                                  <Input
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].total_count`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].total_count`}
                                                    placeholder="Total number of pieces"
                                                    type="number"
                                                    onBlur={e => {
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].total_count`,
                                                        Math.abs(e.target.value)
                                                      );
                                                    }}
                                                    inputProps={{
                                                      min: "0"
                                                    }}
                                                  />
                                                </FormControl>

                                                <FormControl
                                                  style={{
                                                    width: "50%"
                                                  }}
                                                >
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`}
                                                  >
                                                    Measured weight (in pounds)
                                                  </InputLabel>
                                                  <Input
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`}
                                                    placeholder="Total weight"
                                                    type="number"
                                                    onBlur={e => {
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`,
                                                        formatFloat(
                                                          e.target.value
                                                        )
                                                      );
                                                    }}
                                                    inputProps={{
                                                      step: "0.01",
                                                      min: "0"
                                                    }}
                                                  />
                                                </FormControl>

                                                <FormControl
                                                  style={{
                                                    width: "50%",
                                                    paddingRight: "6px"
                                                  }}
                                                >
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`}
                                                  >
                                                    Price per lb
                                                  </InputLabel>
                                                  <Input
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`}
                                                    placeholder="Price per lb"
                                                    type="number"
                                                    onBlur={e => {
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`,
                                                        formatFloat(
                                                          e.target.value
                                                        )
                                                      );
                                                    }}
                                                    inputProps={{
                                                      step: "0.01",
                                                      min: "0"
                                                    }}
                                                  />
                                                </FormControl>

                                                <FormControl fullWidth>
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].sold_to_vendor`}
                                                  >
                                                    Fish sold to
                                                  </InputLabel>
                                                  <RadioGroup
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].sold_to_vendor`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].sold_to_vendor`}
                                                    className={
                                                      classes.radioGroup
                                                    }
                                                    row={true}
                                                    value={`${values.sales_order[saleIndex].items[orderItemIndex].sold_to_vendor}`}
                                                    onChange={e => {
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].sold_to_vendor`,
                                                        e.target.value ===
                                                          "true"
                                                      );
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].vendor_business_license_number`,
                                                        ""
                                                      );
                                                      setReportError("");
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      value="true"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Vendor"
                                                    />
                                                    <FormControlLabel
                                                      value="false"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Cash"
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                                {values.sales_order[saleIndex]
                                                  .items[orderItemIndex]
                                                  .sold_to_vendor && (
                                                  <FormControl fullWidth>
                                                    <span>
                                                      Vendor license number
                                                    </span>

                                                    <Field
                                                      id={`sales_order[${saleIndex}].items[${orderItemIndex}].vendor_business_license_number`}
                                                      name={`sales_order[${saleIndex}].items[${orderItemIndex}].vendor_business_license_number`}
                                                      component="select"
                                                    >
                                                      <option
                                                        value=""
                                                        disabled
                                                        selected
                                                      >
                                                        Select vendor
                                                      </option>
                                                      {vendors.map(
                                                        (vendor, itemIndex) => (
                                                          <option
                                                            value={vendor}
                                                            key={`fishing-vendor-${itemIndex}`}
                                                          >
                                                            {vendor}
                                                          </option>
                                                        )
                                                      )}
                                                    </Field>
                                                  </FormControl>
                                                )}

                                                <FormControl>
                                                  <Button
                                                    className={
                                                      classes.buttonOutline
                                                    }
                                                    style={{ margin: "auto" }}
                                                    type="button"
                                                    onClick={() =>
                                                      arrayHelpers2.remove(
                                                        orderItemIndex
                                                      )
                                                    }
                                                  >
                                                    Remove Sale
                                                  </Button>
                                                </FormControl>
                                              </div>
                                            );
                                          }
                                        )}
                                        <FormControl fullWidth>
                                          <Button
                                            className={classes.buttonOutline}
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers2.push({
                                                ...initialSaleState
                                              })
                                            }
                                          >
                                            + Add Sales Record
                                          </Button>
                                        </FormControl>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                    <FormControl fullWidth className={classes.bottomButtonBar}>
                      <div>
                        <span>
                          Step {pageOffset}/{SchemaValidation.length}
                        </span>
                        {reportError && (
                          <span className={classes.errorSpan}>
                            {reportError}
                          </span>
                        )}
                      </div>
                      <div>
                        <span>Next: Review &amp; Submit Report</span>

                        <Button
                          data-cy="submit"
                          className={classes.proceedButton}
                          onClick={() => {
                            if (
                              values.sales_order[0] &&
                              values.sales_order[0].items &&
                              values.sales_order[0].items.length > 0
                            ) {
                              SchemaValidation[pageOffset - 1]
                                .validate(values)
                                .then(valid => {
                                  const eventDetails = groupedFishingDetailsFromEvents(
                                    values.fishing_events
                                  );
                                  const salesDetails = totalsFromSales(
                                    values.sales_order[0].items
                                  );
                                  const eventSpecies = Object.keys(
                                    eventDetails
                                  );
                                  eventSpecies.forEach(speciesId => {
                                    if (
                                      salesDetails[`${speciesId}`] &&
                                      salesDetails[`${speciesId}`]
                                        .total_weight_lbs >
                                        eventDetails[`${speciesId}`]
                                          .total_weight_lbs
                                    ) {
                                      throw new Error(
                                        "Total weight sold cannot be more than total weight caught"
                                      );
                                    }
                                    if (
                                      salesDetails[`${speciesId}`] &&
                                      salesDetails[`${speciesId}`].total_count >
                                        eventDetails[`${speciesId}`].total_count
                                    ) {
                                      throw new Error(
                                        "Total count sold cannot be more than total count caught"
                                      );
                                    }
                                  });

                                  saveReport({
                                    newReport: false,
                                    originalReport: savedFormValues,
                                    revisionReport: {
                                      ...savedFormValues,
                                      ...values,
                                      fishing_events: [
                                        ...values.fishing_events
                                      ],
                                      id: currentReportId,
                                      map_areas_id: currentReportMapArea
                                        ? currentReportMapArea.id
                                        : activeMapAreaId
                                    },
                                    id: currentReportId
                                  });
                                  setPageOffset(pageOffset + 1);
                                  setSavedFormValues(values);
                                  scrollToTop();
                                  setProgreeBarWidth(
                                    `${
                                      ((pageOffset + 1) /
                                        SchemaValidation.length) *
                                      100
                                    }%`
                                  );
                                  setReportError("");
                                })
                                .catch(err => {
                                  setReportError(err.message);
                                });
                            } else {
                              setPageOffset(pageOffset + 1);
                              scrollToTop();
                              setProgreeBarWidth(
                                `${
                                  ((pageOffset + 1) / SchemaValidation.length) *
                                  100
                                }%`
                              );
                              setReportError("");
                            }
                          }}
                        >
                          {values.sales_order[0] &&
                          values.sales_order[0].items &&
                          values.sales_order[0].items.length ? (
                            <span>Save &amp; continue</span>
                          ) : (
                            <span>Skip &amp; continue </span>
                          )}
                        </Button>
                      </div>
                    </FormControl>
                  </div>

                  {/*********************************************/}
                  {/***** PAGE 7: Review Report Information *****/}
                  {/*********************************************/}
                  <div
                    className={
                      pageOffset === 7 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Fishing Report
                      </Typography>
                      <HeaderSummary values={values} />
                    </CardHeader>

                    <Typography className={classes.smallHeader} variant="h6">
                      Review Report Information
                      <Button
                        className={classes.buttonOutline}
                        style={{ float: "right", marginBottom: "0.2rem" }}
                        type="button"
                        onClick={() => {
                          setShowDeleteReportModal(true);
                        }}
                      >
                        Delete this report
                      </Button>
                    </Typography>

                    <div
                      className={classes.fieldSpacingWrapper}
                      style={{ position: "relative" }}
                    >
                      <span
                        className={classes.editButton}
                        onClick={() => setPageOffset(1)}
                      >
                        Edit
                      </span>
                      <Typography className={classes.smallHeader} variant="h6">
                        Trip Information
                      </Typography>
                      <span>
                        {moment(values.departure_datetime).format(
                          "MMMM DD, YYYY"
                        )}{" "}
                        &middot; {values.departure_location} →{" "}
                        {moment(values.arrival_datetime).format(
                          "MMMM DD, YYYY"
                        )}{" "}
                        &middot; {values.arrival_location} Vessel:{" "}
                        {values.vessel_license_number} &middot;{" "}
                        {values.is_charter
                          ? `Charter trip`
                          : `Non-charter trip`}
                      </span>
                    </div>

                    <div
                      className={classes.fieldSpacingWrapper}
                      style={{ position: "relative" }}
                    >
                      <span
                        className={classes.editButton}
                        onClick={() => setPageOffset(2)}
                      >
                        Edit
                      </span>
                      <Typography className={classes.smallHeader} variant="h6">
                        Crew Information
                      </Typography>
                      {values.crew_member &&
                        values.crew_member.length > 0 &&
                        values.crew_member
                          .filter(
                            member => !!member.first_name || !!member.last_name
                          )
                          .map((crewMember, crewMemberIndex) => {
                            return (
                              <div key={`crewmember_${crewMemberIndex}`}>
                                <span>
                                  {crewMember.first_name} {crewMember.last_name}{" "}
                                  &middot; License:{" "}
                                  {crewMember.business_license_number
                                    ? crewMember.business_license_number
                                    : `N/A`}{" "}
                                </span>
                              </div>
                            );
                          })}
                    </div>

                    <div
                      className={classes.fieldSpacingWrapper}
                      style={{ position: "relative" }}
                    >
                      <span
                        className={classes.editButton}
                        onClick={() => setPageOffset(3)}
                      >
                        Edit
                      </span>
                      <Typography className={classes.smallHeader} variant="h6">
                        Fishing Events
                      </Typography>
                      <span>
                        Total Fishing Event:{" "}
                        {savedFormValues.fishing_events.length}
                      </span>
                      <Divider className={classes.dividerSmallPadding} />
                      {savedFormValues.fishing_events &&
                        savedFormValues.fishing_events.length > 0 &&
                        savedFormValues.fishing_events.map(
                          (fishingEvent, fishingEventIndex) => {
                            return (
                              <div
                                key={`fishing_event_review_${fishingEventIndex}`}
                                className={classes.reviewItem}
                              >
                                <span>
                                  {findFishingMethod(
                                    fishingEvent.fishing_method_id
                                  )}{" "}
                                  &middot;{" "}
                                  {moment(fishingEvent.start_time).format(
                                    "MMMM Do YYYY, h:mm A"
                                  )}
                                  &middot; {fishingEvent.fishing_hours}hrs{" "}
                                  &middot;{" "}
                                  {speciesListFromEvent(fishingEvent, species)}{" "}
                                  &middot; {totalPoundsFromEvent(fishingEvent)}{" "}
                                  Estimated weight (in pounds)
                                </span>
                              </div>
                            );
                          }
                        )}
                    </div>

                    {values.sales_order.length > 0 &&
                      values.sales_order[0].items &&
                      values.sales_order[0].items.length > 0 &&
                      values.sales_order.map((saleOrder, saleIndex) => {
                        return (
                          <div
                            key={`sale-order-${saleIndex}`}
                            className={classes.fieldSpacingWrapper}
                            style={{ position: "relative" }}
                          >
                            <span
                              className={classes.editButton}
                              onClick={() => setPageOffset(4)}
                            >
                              Edit
                            </span>
                            <Typography
                              className={classes.smallHeader}
                              variant="h6"
                            >
                              Sales Information
                            </Typography>
                            <span>
                              {saleOrder.items.length} Total{" "}
                              {saleOrder.items.length === 1 ? "Sale" : "Sales"}{" "}
                              Recorded
                            </span>
                            <Divider className={classes.dividerSmallPadding} />
                            {saleOrder.items.map((sale, itemIndex) => {
                              return (
                                <div
                                  key={`sale-${itemIndex}`}
                                  className={classes.reviewItem}
                                >
                                  <span>
                                    {sale.species_id &&
                                      species.find(spec => {
                                        return sale.species_id == spec.id;
                                      }).common_name}{" "}
                                    &middot; {sale.total_count} Count &middot; $
                                    {sale.price_per_lb_usd}/lb &middot; Vendor
                                    license{" "}
                                    {sale.vendor_business_license_number}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}

                    {!savedFormValues.verified && (
                      <FormControl
                        fullWidth
                        className={classes.bottomButtonBar}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="verified"
                                id="verified"
                                onClick={e => {
                                  setSavedFormValues({
                                    ...values,
                                    verified: e.target.checked
                                  });
                                }}
                              />
                            }
                            label="The above information is accurate"
                          ></FormControlLabel>
                        </FormGroup>
                      </FormControl>
                    )}

                    {savedFormValues.verified && (
                      <FormControl
                        fullWidth
                        className={classes.bottomButtonBar}
                      >
                        <div>
                          {reportError && (
                            <span className={classes.errorSpan}>
                              {reportError}
                            </span>
                          )}
                        </div>
                        <div>
                          <span>{`You won’t be able to edit or modify this report after you
                      submit`}</span>

                          <Button
                            data-cy="submit"
                            className={classes.proceedButton}
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            Submit Report
                          </Button>
                        </div>
                      </FormControl>
                    )}

                    <PopupModal
                      open={showDeleteReportModal}
                      onCloseModal={() => setShowDeleteReportModal(false)}
                    >
                      <h2>Are you sure you want to delete this report?</h2>
                      <p>You won't be able to undo this action</p>
                      <Button
                        className={classes.buttonModalOptions}
                        onClick={() => {
                          deleteReport(currentReportId);
                          setShowDeleteReportModal(false);
                          setPageOffset(0);
                          setCurrentReportId(null);
                          resetForm();
                        }}
                      >
                        Ok
                      </Button>
                      <Button
                        className={classes.buttonModalOptions}
                        onClick={() => {
                          setShowDeleteReportModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </PopupModal>
                  </div>

                  {/*********************************************/}
                  {/********** PAGE 8: Map Selection ************/}
                  {/*********************************************/}

                  <div
                    className={
                      pageOffset === 8 ? classes.visible : classes.hidden
                    }
                  >
                    {pageOffset === 8 && (
                      <FishedAreasMap
                        zoom={settings.default_map_options.zoom_level}
                        mapArea={currentReportMapArea}
                        defaultMapCenter={defaultMapCenter}
                        mapPinCoordinates={mapPinCoordinates}
                        selectedFishingEventAreaIndex={
                          selectedFishingEventAreaIndex
                        }
                        initialize={() => {
                          setDraftFishingAreaIds(
                            JSON.parse(
                              values.fishing_events[
                                selectedFishingEventAreaIndex
                              ].fished_areas_ids
                            )
                          );
                        }}
                        onClose={() => {
                          setFieldValue(
                            `fishing_events[${selectedFishingEventAreaIndex}].fished_areas_ids`,
                            JSON.stringify(draftFishingAreaIds)
                          );
                          setPageOffset(3);
                          scrollToTop();
                        }}
                        onEachFeature={(feature, layer) => {
                          layer.on({
                            click: function (e) {
                              const trollingId = fishingMethods.find(
                                methodItem => methodItem.type === "Trolling"
                              ).id;

                              const colorOnClick =
                                e.target._path.style.fill !== "rgb(23, 82, 188)"
                                  ? "rgb(23, 82, 188)"
                                  : "#cccccc";

                              let fishingAreasIdsList = draftFishingAreaIds.slice();

                              // If method is not trolling, clear out the styling for all the other squares
                              // so that only a single square appears to be highlighted at a time
                              if (
                                values.fishing_events[
                                  selectedFishingEventAreaIndex
                                ].fishing_method_id != trollingId
                              ) {
                                const layerIds = Object.keys(
                                  layer._map._layers
                                );
                                layerIds.forEach((c, i, a) => {
                                  if (layer._map._layers[c]._path) {
                                    layer._map._layers[c]._path.style.fill =
                                      "#cccccc";
                                  }
                                });
                              }

                              // Set the color of the square that is clicked on
                              e.target._path.style.fill = colorOnClick;

                              // Logic to set the fished_areas_ids value
                              if (draftFishingMethodId == trollingId) {
                                if (fishingAreasIdsList.includes(feature.id)) {
                                  fishingAreasIdsList = fishingAreasIdsList.filter(
                                    item => item != feature.id
                                  );
                                } else {
                                  fishingAreasIdsList.push(feature.id);
                                }
                                setDraftFishingAreaIds(
                                  fishingAreasIdsList.slice()
                                );
                              } else {
                                setDraftFishingAreaIds([feature.id]);
                              }

                              setReportError("");
                            }
                          });

                          // Set color of squares if previously selected
                          if (draftFishingAreaIds.includes(feature.id)) {
                            layer.setStyle({
                              fill: "rgb(23, 82, 188)",
                              color: "rgb(23, 82, 188)"
                            });
                          }
                        }}
                      />
                    )}
                  </div>
                  <ProgressBar progreeBarWidth={progreeBarWidth} />
                </form>
              );
            }}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
};

const StyledReports = withStyles(styles)(Reports);

const mapStateToProps = state => {
  return {
    account: state.account,
    isAuthenticated: state.user.isAuthenticated,
    fishingReports: state.fishingReports,
    species: state.species,
    fishingMethods: state.fishingMethods,
    vendors: state.vendors,
    ports: state.ports,
    mapAreas: state.mapAreas,
    settings: state.settings,
    fields: state.fields
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addReport,
      updateReport,
      deleteReport,
      getReports,
      markFishingReportDone
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StyledReports);
