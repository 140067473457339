import React from "react";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.grey["300"]}`,
    textAlign: "right",
    "&:first-child": {
      textAlign: "left"
    },
    "&:last-child": {
      borderRight: "none"
    }
  },
  head: {
    fontWeight: 400,
    color: theme.palette.text.primary,
    backgroundColor: "#fff"
  },
  body: {
    fontWeight: 300
  }
});

const TableCell = ({ classes, ...rest }) => {
  return <MuiTableCell classes={classes} {...rest} />;
};

export default withStyles(styles)(TableCell);
