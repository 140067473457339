import { westpac } from "../services";
import { getApprovedReports } from "./dashboard";
import moment from "moment";

export const ADD_FISHING_REPORT = "ADD_FISHING_REPORT";
export const UPDATE_FISHING_REPORT = "UPDATE_FISHING_REPORT";
export const DELETE_FISHING_REPORT = "DELETE_FISHING_REPORT";
export const GET_FISHING_REPORTS = "GET_FISHING_REPORTS";
export const UPDATE_FISHING_REPORT_DONE = "UPDATE_FISHING_REPORT_DONE";

const uuidv1 = require("uuid/v1");

export const parseReportsData = data => {
  const parsedReports = data.fishing_reports.map(r => {
    return {
      id: r.id,
      created_at: r.created_at,
      updated_at: r.updated_at,
      submitted_at: r.submitted_at,
      is_approved: r.is_approved,
      reason: r.reason,
      done: r.done,
      map_areas_id: r.map_areas_id,
      fishing_trip: {
        departure_datetime: moment(r.departure_datetime).format(
          "YYYY-MM-DDTHH:mm"
        ),
        departure_location: r.departure_location,
        arrival_datetime: moment(r.arrival_datetime).format("YYYY-MM-DDTHH:mm"),
        arrival_location: r.arrival_location,
        vessel_license_number: r.vessel_license_number,
        is_charter: r.is_charter,
        crew_member: parseCrewMembers(r.crew_member),
        fishing_events: parseFishingEvents(r.fishing_events),
        sales_order: parseSalesOrders(r.sales_order),
        fish_lost: parseFishLost(r.fish_lost)
      }
    };
  });

  return parsedReports;
};

function parseCrewMembers(crew_member) {
  return crew_member.map(
    ({ first_name, last_name, business_license_number, id }) => ({
      id,
      first_name,
      last_name,
      business_license_number
    })
  );
}

function parseFishingEvents(fishing_events) {
  return fishing_events.map(event => {
    return {
      description: event.description,
      fishing_method_id: event.fishing_method_id, //TODO: GET /fishing-reports should return string not the id of a fishing_method
      target_species: event.target_species.map(target_species => {
        return target_species.id;
      }),
      start_time: moment(event.start_time).format("YYYY-MM-DDTHH:mm"),
      fishing_hours: event.fishing_hours,
      fished_areas_ids: event.fished_areas_ids,
      number_of_gears: event.number_of_gears,
      number_of_spears: event.number_of_spears,
      catches: parseFishingEventCatches([...event.catches])
    };
  });
}

function parseFishingEventCatches(fishing_event_catches) {
  return fishing_event_catches.map(fishing_event_catch => {
    return {
      total_count: fishing_event_catch.total_count,
      total_weight_lbs: fishing_event_catch.total_weight_lbs,
      is_kept: fishing_event_catch.is_kept,
      attachments: fishing_event_catch.attachments,
      species_id: fishing_event_catch.species_id
    };
  });
}

function parseSalesOrders(sales_order) {
  return sales_order.map(sales_order => {
    return {
      created_at: sales_order.created_at,
      updated_at: sales_order.updated_at,
      fishing_report_id: sales_order.fishing_report_id,
      items: sales_order.items
    };
  });
}

function parseFishLost(fish_lost) {
  if (!fish_lost) {
    return [];
  }
  return fish_lost.map(fish_lost => {
    return {
      species_id: fish_lost.species_id,
      predator_species_id: fish_lost.predator_species_id,
      lost_to_predator: fish_lost.lost_to_predator,
      total_count_lost: fish_lost.total_count_lost,
      total_weight_lbs_lost: fish_lost.total_weight_lbs_lost,
      disposition: fish_lost.disposition
    };
  });
}

export const getReports = () => {
  return dispatch => {
    return westpac
      .get("/fishing-reports")
      .then(({ data }) => {
        const parsedReports = parseReportsData(data);

        dispatch({
          type: GET_FISHING_REPORTS,
          payload: {
            data: parsedReports
          }
        });

        dispatch(getApprovedReports(parsedReports));

        return parsedReports;
      })
      .catch(err => {
        throw err;
      });
  };
};

export const addReport = payload => {
  if (!navigator.onLine) {
    return dispatch => {
      const tempId = `temp-${uuidv1()}`;
      const parsedReport = parseReportsData({
        fishing_reports: [{ ...payload, id: tempId }]
      });
      dispatch({
        type: ADD_FISHING_REPORT,
        payload: {
          report: { ...parsedReport[0] }
        }
      });
      return new Promise((resolve, reject) => {
        resolve({ ...parsedReport, id: tempId });
      });
    };
  }

  return dispatch => {
    return westpac
      .post("/fishing-report", { ...payload })
      .then(({ data }) => {
        const parsedReport = parseReportsData({
          fishing_reports: [{ ...data }]
        });
        parsedReport[0].fishing_trip.arrival_datetime = `${payload.arrival_datetime}`;
        parsedReport[0].fishing_trip.departure_datetime = `${payload.arrival_datetime}`;
        dispatch({
          type: ADD_FISHING_REPORT,
          payload: {
            report: { ...parsedReport[0] }
          }
        });
        return { ...parsedReport[0] };
      })
      .catch(err => {
        throw err;
      });
  };
};

export const updateReport = payload => {
  if (!navigator.onLine) {
    return dispatch => {
      dispatch({
        type: UPDATE_FISHING_REPORT,
        payload: {
          report: payload.originalReport,
          revision: payload.revisionReport
        }
      });
      return new Promise((resolve, reject) => {
        resolve({ ...payload.revisionReport });
      });
    };
  }

  if (
    navigator.onLine &&
    typeof payload.id === "string" &&
    payload.id.includes("temp")
  ) {
    const payloadWithoutId = { ...payload.revisionReport };
    delete payloadWithoutId.id;

    return dispatch => {
      return westpac
        .post("/fishing-report", { ...payloadWithoutId })
        .then(({ data }) => {
          const parsedReport = parseReportsData({
            fishing_reports: [{ ...data }]
          });
          dispatch({
            type: ADD_FISHING_REPORT,
            payload: {
              report: { ...parsedReport[0] }
            }
          });

          dispatch({
            type: DELETE_FISHING_REPORT,
            payload: {
              id: payload.id
            }
          });
          return { ...parsedReport[0] };
        })
        .catch(err => {
          throw err;
        });
    };
  }

  return dispatch => {
    return westpac
      .put(`/fishing-report/${payload.id}`, payload.revisionReport)
      .then(({ data }) => {
        const parsedReport = parseReportsData({
          fishing_reports: [{ ...data }]
        });
        dispatch({
          type: UPDATE_FISHING_REPORT,
          payload: {
            report: payload.originalReport,
            revision: { ...parsedReport[0] }
          }
        });
        return { ...parsedReport[0] };
      })
      .catch(err => {
        throw err;
      });
  };
};

export const deleteReport = id => {
  return dispatch => {
    return westpac
      .delete(`/fishing-report/${id}`)
      .then(data => {
        dispatch({
          type: DELETE_FISHING_REPORT,
          payload: {
            id
          }
        });
        return id;
      })
      .catch(err => {
        throw err;
      });
  };
};

export const markFishingReportDone = id => {
  return dispatch => {
    return westpac
      .put(`/fishing-report-done/${id}`)
      .then(data => {
        dispatch({
          type: UPDATE_FISHING_REPORT_DONE,
          payload: {
            id
          }
        });
        return id;
      })
      .catch(err => {
        console.log("err", err);
        throw err;
      });
  };
};
