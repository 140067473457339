import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  container: {
    maxWidth: "450px"
  },
  palette: {
    primary: {
      main: "rgba(35,64,142,1)",
      light: "rgba(23,82,188,1)",
      dark: "#2A2A2A"
    }
  },
  typography: {
    h5: {
      fontWeight: 400,
      fontSize: "1.375rem" // 25px
    },
    h6: {
      fontWeight: 400,
      fontSize: "1.375rem" // 22px
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1rem" // 16px
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "0.875rem" // 14px
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem" // 16px
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem" // 14px
    },
    button: {
      fontSize: "0.875rem", // 14px
      textTransform: "none"
    }
  }
});

export default theme;
