import React from "react";
import MuiDivider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";
import PropTypes from "prop-types";

const styles = theme => ({
  divider: {
    margin: `${theme.spacing(1)}px 0`
  },
  none: { margin: 0 },
  normal: { margin: `${theme.spacing(1)}px 0` },
  dense: { margin: `${theme.spacing(2)}px 0` }
});

const Divider = props => <MuiDivider {...props} />;

Divider.propTypes = {
  margin: PropTypes.oneOf(["none", "normal", "dense"])
};

export default Divider;
