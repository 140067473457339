import {
  LOGIN_USER,
  LOGOUT_USER,
  DISABLE_RESET_PASSWORD_PROMPT
} from "../actions/auth";

export const INIITIAL_USER_STATE = {
  isAuthenticated: false,
  isFirstLogin: false
};

export default function userReducer(state = INIITIAL_USER_STATE, action = {}) {
  switch (action.type) {
    case DISABLE_RESET_PASSWORD_PROMPT:
      return {
        ...state,
        isFirstLogin: false
      };
    case LOGIN_USER:
      return {
        isAuthenticated: true,
        isFirstLogin: action.payload.user.isFirstLogin
      };
    case LOGOUT_USER:
      return { isAuthenticated: false };
    default:
      return state;
  }
}
