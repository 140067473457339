import React from "react";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
import { Button } from "../index";
import GeoJsonLayer from "./GeoJsonLayer";
import L from "leaflet";
import { fetchMapArea } from "../../actions/map-areas";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default class FishedAreasMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapAreaGeojson: null
    };
  }

  async componentDidMount() {
    if (!this.state.mapAreaGeojson) {
      const mapAreaGeojson = await fetchMapArea(this.props.mapArea.file_url);
      this.setState({ mapAreaGeojson });
    }
    this.props.initialize();
  }

  render() {
    const { defaultMapCenter, mapPinCoordinates, zoom } = this.props;
    const { mapAreaGeojson } = this.state;
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "10000"
        }}
      >
        <Map
          center={defaultMapCenter}
          zoom={zoom}
          minZoom={2}
          maxZoom={14}
          worldCopyJump={true}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={mapPinCoordinates}
            style={{
              zIndex: "10001"
            }}
          ></Marker>
          {mapAreaGeojson ? (
            <GeoJsonLayer
              key={
                /* Break React render check */
                `${Math.random()}`
              }
              selectedFishingEventAreaIndex={
                this.props.selectedFishingEventAreaIndex
              }
              onEachFeature={this.props.onEachFeature}
              areas={mapAreaGeojson}
            />
          ) : null}
        </Map>
        <Button
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            zIndex: "10001",
            background: "rgba(35,64,142,1)",
            color: "#fff",
            width: "100%",
            borderRadius: "none"
          }}
          onClick={() => {
            this.props.onClose();
          }}
        >
          Save selected area
        </Button>
      </div>
    );
  }
}

FishedAreasMap.defaultProps = {
  zoom: 7,
  initialize: () => {},
  onClose: () => {}
};
