import { westpac } from "../services";

export const GET_FISHING_METHODS = "GET_FISHING_METHODS";

export const getFishingMethods = () => {
  return dispatch => {
    return westpac.get("/fishing-method").then(({ data }) => {
      dispatch({
        type: GET_FISHING_METHODS,
        payload: {
          fishingMethods: data.fishing_methods
        }
      });

      return data;
    });
  };
};
