import React from "react";
import { withStyles } from "@material-ui/styles";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Yup from "yup";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  Form,
  TextField,
  Typography
} from "../../components";
import { updateAccount } from "../../actions/account";
import { disablePasswordResetPrompt } from "../../actions/auth";
import { westpac } from "../../services";
import { password, re_entered_password } from "./schema";

const styles = theme => ({
  cardBody: {
    paddingBottom: theme.spacing(4)
  },
  cardFooter: {
    padding: "24px 60px 60px",

    textAlign: "center"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `0 ${theme.spacing(4)}px`,
    width: "100%"
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textDecoration: "none"
  },
  logo: {
    fontWeight: 900,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  }
});

function passwordMatch(password, re_entered_password) {
  if (password.trim() === re_entered_password.trim()) {
    return true;
  }

  return false;
}

const ResetPassword = ({
  classes,
  id,
  navigate,
  disablePasswordResetPrompt
}) => {
  return (
    <Container>
      <Card>
        <CardHeader title>
          <Typography className={classes.logo} variant="h6">
            Fisher
          </Typography>
          <Typography variant="h5">Please set a new password</Typography>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <Form
            initialValues={{
              password: "",
              re_entered_password: ""
            }}
            validationSchema={Yup.object().shape({
              password: password.required("Please enter a New Password."),
              re_entered_password: re_entered_password.required(
                "Please reenter your Password."
              )
            })}
            onSubmit={(values, actions) => {
              if (passwordMatch(values.password, values.re_entered_password)) {
                westpac
                  .put(`/user/reset-password`, {
                    password: values.password,
                    re_entered_password: values.password
                  })
                  .then(response => {
                    if (response.status === 200) {
                      updateAccount({ account: { password: values.password } });
                      alert("Your password has been changed.");
                      navigate("/app/reports");
                    } else {
                      alert(
                        "There was an issue with the server. Please try again at a later time."
                      );
                    }
                  })
                  .catch(err => {
                    alert(
                      "There was an issue with the server. Please try again at a later time."
                    );
                  });
              } else {
                alert("Your password and re-entered password do not match.");
              }
            }}
            render={({ values, handleSubmit }) => {
              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    name="password"
                    label="New Password"
                    placeholder="Minimum 8 Characters"
                    type="password"
                  />
                  <TextField
                    name="re_entered_password"
                    placeholder="Reenter New Password"
                    label="Reenter New Password"
                    type="password"
                  />
                  <Button
                    className={classes.submitBtn}
                    color="primary"
                    variant="contained"
                    type="submit"
                    wide
                  >
                    Reset Password
                  </Button>
                </form>
              );
            }}
          />
        </CardBody>
        <CardFooter className={classes.cardFooter} overline>
          <Typography color="primary" variant="subtitle2">
            <Link
              className={classes.link}
              to="/app/reports"
              onClick={() => disablePasswordResetPrompt()}
            >
              Skip this step and set a new password later &#8594;
            </Link>
          </Typography>
        </CardFooter>
      </Card>
    </Container>
  );
};

const StyledResetPassword = withStyles(styles)(ResetPassword);

export default connect(
  state => ({ id: state.account.id }),
  dispatch =>
    bindActionCreators({ disablePasswordResetPrompt, updateAccount }, dispatch)
)(StyledResetPassword);
