import React from "react";
import { withStyles } from "@material-ui/styles";
import MuiGrid from "@material-ui/core/Grid";

const styles = theme => ({
  item: {
    padding: `0 ${theme.spacing(1)}px`
  }
});

const GridItem = ({ classes, ...rest }) => {
  return <MuiGrid item {...rest} />;
};

export default withStyles(styles)(GridItem);
